import { useState, useEffect, useContext, useRef } from "react";
import { useStopwatch } from "react-timer-hook";
import DevQuestion from "./MathQuestion";
import axios from "axios";
import { motion } from "framer-motion";
import { UserContext } from "../../context/UserContext";
import { useLocation } from "react-router-dom";
import { BASELINE } from "../../util";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Select from 'react-select'
import NavBar from "./navbar";

const topics = {
  "#1 Limits and Continuity": {
    "Concept of a Limit": {
      "topics": [
        {
          "title": "1.1 Introducing Calculus: Can Change Occur at an Instant?",
          "code": "APCALC_U1_1"
        },
        {
          "title": "1.2 Defining Limits and Using Limit Notation",
          "code": "APCALC_U1_2"
        },
        {
          "title": "1.3 Estimating Limit Values from Graphs",
          "code": "APCALC_U1_3"
        },
        {
          "title": "1.4 Estimating Limit Values from Tables",
          "code": "APCALC_U1_4"
        }
      ]
    },
    "Calculating Limit": {
      "topics": [
        {
          "title": "1.5 Determining Limits Using Algebraic Properties of Limits",
          "code": "APCALC_U1_5"
        },
        {
          "title": "1.6 Determining Limits Using Algebraic Manipulation",
          "code": "APCALC_U1_6"
        },
        {
          "title": "1.7 Selecting Procedures for Determining Limits",
          "code": "APCALC_U1_7"
        },
        {
          "title": "1.8 Determining Limits Using the Squeeze Theorem",
          "code": "APCALC_U1_8"
        },
        {
          "title": "1.9 Connecting Multiple Representations of Limits",
          "code": "APCALC_U1_9"
        }
      ]
    },
    "Continuity": {
      "topics": [
        {
          "title": "1.10 Exploring Types of Discontinuities",
          "code": "APCALC_U1_10"
        },
        {
          "title": "1.11 Defining Continuity at a Point",
          "code": "APCALC_U1_11"
        },
        {
          "title": "1.12 Confirming Continuity over an Interval",
          "code": "APCALC_U1_12"
        },
        {
          "title": "1.13 Removing Discontinuities",
          "code": "APCALC_U1_13"
        }
      ]
    },
    "Asymptotes & IVT": {
      "topics": [
        {
          "title": "1.14 Connecting Infinite Limits and Vertical Asymptotes",
          "code": "APCALC_U1_14"
        },
        {
          "title": "1.15 Connecting Limits at Infinity and Horizontal Asymptotes",
          "code": "APCALC_U1_15"
        },
        {
          "title": "1.16 Working with the Intermediate Value Theorem (IVT)",
          "code": "APCALC_U1_16"
        }
      ]
    }
  },

  "#2 Differentiation: Definition and Basic Derivative Rules": {
    "Definition and Existence of Derivatives": {
      "topics": [
        {
          "title": "2.1 Defining Average and Instantaneous Rates of Change at a Point",
          "code": "APCALC_U2_1"
        },
        {
          "title": "2.2 Defining the Derivative of a Function and Using Derivative Notation",
          "code": "APCALC_U2_2"
        },
        {
          "title": "2.3 Estimating Derivatives of a Function at a Point",
          "code": "APCALC_U2_3"
        },
        {
          "title": "2.4 Connecting Differentiability and Continuity: Determining When Derivatives Do and Do Not Exist",
          "code": "APCALC_U2_4"
        }
      ]
    },
    "Calculating Derivatives": {
      "topics": [
        {
          "title": "2.5 Applying the Power Rule",
          "code": "APCALC_U2_5"
        },
        {
          "title": "2.6 Derivative Rules: Constant, Sum, Difference, and Constant Multiple",
          "code": "APCALC_U2_6"
        },
        {
          "title": "2.7 Derivatives of cos x, sin x, e^x, and ln x",
          "code": "APCALC_U2_7"
        },
        {
          "title": "2.8 The Product Rule",
          "code": "APCALC_U2_8"
        },
        {
          "title": "2.9 The Quotient Rule",
          "code": "APCALC_U2_9"
        },
        {
          "title": "2.10 Finding the Derivatives of Tangent, Cotangent, Secant, and/or Cosecant Functions",
          "code": "APCALC_U2_10"
        }
      ]
    }
  },

  "#3 Differentiation: Composite, Implicit, and Inverse Functions": {
    "Advanced Derivative Rules": {
      "topics": [
        {
          "title": "3.1 The Chain Rule",
          "code": "APCALC_U3_1"
        },
        {
          "title": "3.2 Implicit Differentiation",
          "code": "APCALC_U3_2"
        }
      ]
    },
    "Inverse Functions and Higher-Order Derivatives": {
      "topics": [
        {
          "title": "3.3 Differentiating Inverse Functions",
          "code": "APCALC_U3_3"
        },
        {
          "title": "3.4 Differentiating Inverse Trigonometric Functions",
          "code": "APCALC_U3_4"
        },
        {
          "title": "3.5 Selecting Procedures for Calculating Derivatives",
          "code": "APCALC_U3_5"
        },
        {
          "title": "3.6 Calculating HigherOrder Derivatives",
          "code": "APCALC_U3_6"
        }
      ]
    }
  },

  "#4 Contextual Applications of Differentiation": {
    "Derivatives in Context": {
      "topics": [
        {
          "title": "4.1 Interpreting the Meaning of the Derivative in Context",
          "code": "APCALC_U4_1"
        },
        {
          "title": "4.2 Straight-Line Motion: Connecting Position, Velocity, and Acceleration",
          "code": "APCALC_U4_2"
        },
        {
          "title": "4.3 Rates of Change in Applied Contexts Other Than Motion",
          "code": "APCALC_U4_3"
        }
      ]
    },
    "Related Rates": {
      "topics": [
        {
          "title": "4.4 Introduction to Related Rates",
          "code": "APCALC_U4_4"
        },
        {
          "title": "4.5 Solving Related Rates Problems",
          "code": "APCALC_U4_5"
        }
      ]
    },
    "Linearity and L'Hospital's Rule": {
      "topics": [
        {
          "title": "4.6 Approximating Values of a Function Using Local Linearity and Linearization",
          "code": "APCALC_U4_6"
        },
        {
          "title": "4.7 Using L'Hospital's Rule for Determining Limits of Indeterminate Forms",
          "code": "APCALC_U4_7"
        }
      ]
    }
  },

  "#5 Analytical Applications of Differentiation": {
    "MVT and EVT": {
      "topics": [
        {
          "title": "5.1 Using the Mean Value Theorem",
          "code": "APCALC_U5_1"
        },
        {
          "title": "5.2 Extreme Value Theorem, Global Versus Local Extrema, and Critical Points",
          "code": "APCALC_U5_2"
        }
      ]
    },
    "Function Behavior": {
      "topics": [
        {
          "title": "5.3 Determining Intervals on Which a Function Is Increasing or Decreasing",
          "code": "APCALC_U5_3"
        },
        {
          "title": "5.4 Using the First Derivative Test to Determine Relative (Local) Extrema",
          "code": "APCALC_U5_4"
        },
        {
          "title": "5.5 Using the Candidates Test to Determine Absolute (Global) Extrema",
          "code": "APCALC_U5_5"
        },
        {
          "title": "5.6 Determining Concavity of Functions over Their Domains",
          "code": "APCALC_U5_6"
        },
        {
          "title": "5.7 Using the Second Derivative Test to Determine Extrema",
          "code": "APCALC_U5_7"
        }
      ]
    },
    "Optimization": {
      "topics": [
        {
          "title": "5.10 Introduction to Optimization Problems",
          "code": "APCALC_U5_10"
        },
        {
          "title": "5.11 Solving Optimization Problems",
          "code": "APCALC_U5_11"
        }
      ]
    }
  },

  "#6 Integration and Accumulation of Change": {
    "Concept of Integration": {
      "topics": [
        {
          "title": "6.1 Exploring Accumulations of Change",
          "code": "APCALC_U6_1"
        },
        {
          "title": "6.2 Approximating Areas with Riemann Sums",
          "code": "APCALC_U6_2"
        },
        {
          "title": "6.3 Riemann Sums, Summation Notation, and Definite Integral Notation",
          "code": "APCALC_U6_3"
        }
      ]
    },
    "Fundamental Theorem of Calculus": {
      "topics": [
        {
          "title": "6.4 The Fundamental Theorem of Calculus and Accumulation Functions",
          "code": "APCALC_U6_4"
        },
        {
          "title": "6.5 Interpreting the Behavior of Accumulation Functions Involving Area",
          "code": "APCALC_U6_5"
        },
        {
          "title": "6.6 Applying Properties of Definite Integrals",
          "code": "APCALC_U6_6"
        },
        {
          "title": "6.7 The Fundamental Theorem of Calculus and Definite Integrals",
          "code": "APCALC_U6_7"
        },
        {
          "title": "6.8 Finding Antiderivatives and Indefinite Integrals: Basic Rules",
          "code": "APCALC_U6_8"
        },
        {
          "title": "6.9 Integrating Using Substitution",
          "code": "APCALC_U6_9"
        },
        {
          "title": "6.10 Integrating Functions Using Long Division and Completing the Square",
          "code": "APCALC_U6_10"
        },
        {
          "title": "6.11 Integrating Using Integration by Parts",
          "code": "APCALC_U6_11"
        },
        {
          "title": "6.12 Using Linear Partial Fractions",
          "code": "APCALC_U6_12"
        }
      ]
    },
    "Integration Techniques": {
      "topics": [
        {
          "title": "6.13 Evaluating Improper Integrals",
          "code": "APCALC_U6_13"
        },
        {
          "title": "6.14 Calculating Integrals for a Function of a Function",
          "code": "APCALC_U6_14"
        }
      ]
    }
  },

  "#7 Differential Equations": {
    "Analyzing Differential Equations": {
      "topics": [
        {
          "title": "7.1 Modeling Situations with Differential Equations",
          "code": "APCALC_U7_1"
        },
        {
          "title": "7.2 Verifying Solutions for Differential Equations",
          "code": "APCALC_U7_2"
        },
        {
          "title": "7.3 Sketching Slope Fields",
          "code": "APCALC_U7_3"
        },
        {
          "title": "7.4 Reasoning Using Slope Fields",
          "code": "APCALC_U7_4"
        }
      ]
    },
    "Solving Differential Equations": {
      "topics": [
        {
          "title": "7.5 Approximating Solutions Using Euler's Method",
          "code": "APCALC_U7_5"
        },
        {
          "title": "7.6 Finding General Solutions Using Separation of Variables",
          "code": "APCALC_U7_6"
        },
        {
          "title": "7.7 Finding Particular Solutions Using Initial Conditions and Separation of Variables",
          "code": "APCALC_U7_7"
        }
      ]
    },
    "Modeling Differential Equations": {
      "topics": [
        {
          "title": "7.8 Exponential Models with Differential Equations",
          "code": "APCALC_U7_8"
        },
        {
          "title": "7.9 Logistic Models with Differential Equations",
          "code": "APCALC_U7_9"
        }
      ]
    }
  },

  "#8 Applications of Integration": {
    "Definite Integrals in Context": {
      "topics": [
        {
          "title": "8.1 Finding the Average Value of a Function on an Interval",
          "code": "APCALC_U8_1"
        },
        {
          "title": "8.2 Connecting Position, Velocity, and Acceleration of Functions Using Integrals",
          "code": "APCALC_U8_2"
        },
        {
          "title": "8.3 Using Accumulation Functions and Definite Integrals in Applied Contexts",
          "code": "APCALC_U8_3"
        }
      ]
    },
    "Area Between Curves": {
      "topics": [
        {
          "title": "8.4 Finding the Area Between Curves Expressed as Functions of x",
          "code": "APCALC_U8_4"
        },
        {
          "title": "8.5 Finding the Area Between Curves Expressed as Functions of y",
          "code": "APCALC_U8_5"
        },
        {
          "title": "8.6 Finding the Area Between Curves That Intersect at More Than Two Points",
          "code": "APCALC_U8_6"
        }
      ]
    },
    "Volume": {
      "topics": [
        {
          "title": "8.7 Volumes with Cross Sections: Squares and Rectangles",
          "code": "APCALC_U8_7"
        },
        {
          "title": "8.8 Volumes with Cross Sections: Triangles and Semicircles",
          "code": "APCALC_U8_8"
        },
        {
          "title": "8.9 Volume with Disc Method: Revolving Around the x- or y-Axis",
          "code": "APCALC_U8_9"
        },
        {
          "title": "8.10 Volume with Disc Method: Revolving Around Other Axes",
          "code": "APCALC_U8_10"
        },
        {
          "title": "8.11 Volume with Washer Method: Revolving Around the x- or y-Axis",
          "code": "APCALC_U8_11"
        },
        {
          "title": "8.12 Volume with Washer Method: Revolving Around Other Axes",
          "code": "APCALC_U8_12"
        },
        {
          "title": "8.13 Arc Length",
          "code": "APCALC_U8_13"
        },
        {
          "title": "8.14 Calculating the Area of a Region Bounded by Two Polar Curves",
          "code": "APCALC_U8_14"
        }
      ]
    }
  },

  "#9 Parametric Equations_Polar Coordinates_and Vector-Valued Functions": {
    "Parametric Equations": {
      "topics": [
        {
          "title": "9.1 Defining and Differentiating Parametric Equations",
          "code": "APCALC_U9_1"
        },
        {
          "title": "9.2 Second Derivatives of Parametric Equations",
          "code": "APCALC_U9_2"
        },
        {
          "title": "9.3 Finding Arc Lengths of Curves Given by Parametric Equations",
          "code": "APCALC_U9_3"
        }
      ]
    },
    "Vector-Valued Functions": {
      "topics": [
        {
          "title": "9.4 Defining and Differentiating Vector-Valued Functions",
          "code": "APCALC_U9_4"
        },
        {
          "title": "9.5 Integrating Vector-Valued Functions",
          "code": "APCALC_U9_5"
        },
        {
          "title": "9.6 Solving Motion Problems Using Parametric and Vector-Valued Functions",
          "code": "APCALC_U9_6"
        }
      ]
    },
    "Polar Coordinates": {
      "topics": [
        {
          "title": "9.7 Defining Polar Coordinates and Differentiating in Polar Form",
          "code": "APCALC_U9_7"
        },
        {
          "title": "9.8 Finding the Area of a Polar Region or the Area Bounded by a Single Polar Curve",
          "code": "APCALC_U9_8"
        },
        {
          "title": "9.9 Finding the Area of the Region Bounded by Two Polar Curves",
          "code": "APCALC_U9_9"
        }
      ]
    }
  },

  "#10 Infinite Sequences and Series": {
    "Infinite Series": {
      "topics": [
        {
          "title": "10.1 Defining Convergent and Divergent Infinite Series",
          "code": "APCALC_U10_1"
        },
        {
          "title": "10.2 Working with Geometric Series",
          "code": "APCALC_U10_2"
        }
      ]
    },
    "Tests for Convergence and Divergence": {
      "topics": [
        {
          "title": "10.3 The nth Term Test for Divergence",
          "code": "APCALC_U10_3"
        },
        {
          "title": "10.4 Integral Test for Convergence",
          "code": "APCALC_U10_4"
        },
        {
          "title": "10.5 Harmonic Series and p-Series",
          "code": "APCALC_U10_5"
        },
        {
          "title": "10.6 Comparison Tests for Convergence",
          "code": "APCALC_U10_6"
        },
        {
          "title": "10.7 Alternating Series Test for Convergence",
          "code": "APCALC_U10_7"
        },
        {
          "title": "10.8 Ratio Test for Convergence",
          "code": "APCALC_U10_8"
        }
      ]
    },
    "Sum of Series": {
      "topics": [
        {
          "title": "10.9 Determining Absolute or Conditional Convergence",
          "code": "APCALC_U10_9"
        },
        {
          "title": "10.10 Alternating Series Error Bound",
          "code": "APCALC_U10_10"
        }
      ]
    },
    "Power Series": {
      "topics": [
        {
          "title": "10.11 Finding Taylor Polynomial Approximations of Functions",
          "code": "APCALC_U10_11"
        },
        {
          "title": "10.12 Lagrange Error Bound",
          "code": "APCALC_U10_12"
        },
        {
          "title": "10.13 Radius and Interval of Convergence of Power Series",
          "code": "APCALC_U10_13"
        }
      ]
    }
  }
};

const DevQuizAPCALC = () => {
  const {
    seconds,
    minutes,
    start
  } = useStopwatch();

  const boxItemLeftRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [questions, setQuestions] = useState([]);
  const [bookmarkedQuestions, setBookMarkedQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [error, setError] = useState('');
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [status, setStatus] = useState(null);
  const [q_id, setQ_id] = useState(null);
  const [note, setNote] = useState("");
  const [ans, setans] = useState(null);
  const [raion, setRaion] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const [selectopic, settopic] = useState('');
  const [selectskills, setskills] = useState([]);
  const [format, setformat] = useState(null);

  const [state, setState] = useState(true);
  const [sat, setsat] = useState(true);

  const [devmode, setdevmode] = useState(false);
  const [testname, setTestname] = useState([]);

  const [selectedMainTopic, setSelectedMainTopic] = useState(currentQuestion?.category1 || '');
  const [selectedSubTopic, setSelectedSubTopic] = useState(currentQuestion?.category2 || '');
  const [selectedTopic, setSelectedTopic] = useState(currentQuestion?.category3 || '');
  const [code, setcode] = useState(false);

  useEffect(() => {
    setSelectedMainTopic(currentQuestion?.category1 || '');
    setSelectedSubTopic(currentQuestion?.category2 || '');
    setSelectedTopic(currentQuestion?.category3 || '');
  }, [currentQuestion]);

  useEffect(() => {
    const script2 = document.createElement('script');
    script2.src = "https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js";
    script2.integrity = "sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05";
    script2.crossOrigin = "anonymous";
    script2.defer = true;

    script2.onload = () => {
      if (boxItemLeftRef.current) {
        if (window.renderMathInElement) {
          window.renderMathInElement(boxItemLeftRef.current, {
            delimiters: [
              { left: '\\(', right: '\\)', display: false },
              { left: '\\[', right: '\\]', display: true },
              { left: '$$', right: '$$', display: false },
              { left: "$", right: "$", display: false },
            ],
            throwOnError: false,
          });
        }
      }
    };
    document.body.appendChild(script2);

    // Clean up: remove the script when the component unmounts
    return () => {
      document.body.removeChild(script2);
    };
  }, [currentQuestion]); // Trigger this effect whenever the currentQuestion changes

  const handleBookMark = (itemId) => {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          if (question.bookMarked) {
            return { ...question, bookMarked: false };
          } else {
            return { ...question, bookMarked: true };
          }
        } else {
          return question;
        }
      })
    }))
  }

  async function getAPCALCquiz() {
    try {
      let res = await axios.get(BASELINE + "get/apcalc/test", {});
      setTestname(res.data);
    } catch (error) {
      console.error("Error fetching apcalc tests:", error);
      toast.error("Failed to load apcalc test names.");
    }
  }

  useEffect(() => {
    getAPCALCquiz();

    if (isNaN(id)) {
      setcode(true);
      setState(true);
      setStatus("Pass");
      setNote("");
      setans(null);
      setSelectedTopic("");
      setSelectedSkills([]);
      settopic("");
      setskills([]);
      getQuestionsbyid();
      setformat(null);
      setdevmode(true);
    } else {
      setcode(false);
      setState(true);
      setStatus("Pass");
      setNote("");
      setans(null);
      setSelectedTopic("");
      setSelectedSkills([]);
      settopic("");
      setskills([]);
      getQuestions();
      setformat(null);
      setdevmode(false);
    }
  }, [id])

  useEffect(() => {
    if (questions.length > 0 && currentQuestion) {
      let q = questions.find((ques => ques?.id === currentQuestion?.id));
      setQ_id(q.id);
      setCurrentQuestion(q);
    }
  }, [questions])

  async function handleSelectOption(itemId, option) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          return { ...question, selectedOption: option };
        } else {
          return question;
        }
      })
    }))
  }
  async function handleCrossOutOptions(itemId, option) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          let crossedOutOptions = question.crossedOutOptions;
          if (crossedOutOptions.includes(option)) {
            crossedOutOptions = crossedOutOptions.filter((el) => el !== option);
          } else {
            crossedOutOptions.push(option);
          }
          return { ...question, crossedOutOptions: crossedOutOptions };
        } else {
          return question;
        }
      })
    }))
  }
  async function handleAnswerValueChange(itemId, value) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          return { ...question, selectedOption: value };
        } else {
          return question;
        }
      })
    }))
  }

  async function getQuestionsbyid() {
    try {
      setLoadingQuestions(true);
      let res = await axios.get(BASELINE + "questions/apcalc/code", { params: { id: id } });
      if (res.data.length < 1) {
        toast.error("Questions With these topics not found. Try other topics for now by going back")
      } else {
        start();
      }
      let arr = [res.data];

      let mappedQuestions = arr[0].map((question, idx) => {
        return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
      });

      setQuestions(mappedQuestions);
      setCurrentQuestion(mappedQuestions[0]);
      setLoadingQuestions(false);
      setans(arr[0].correct_answer);
      setRaion(mappedQuestions[0].rationale);

      if (Array.isArray(arr) && arr.length > 0) {
        //console.log(arr);
        if (arr[0].practice_category_primary_section) {
          settopic(arr[0].practice_category_primary_section);
        }
        if (arr[0].skills) {
          setskills(arr[0].skills.split(","));
        }
      }
    } catch (err) {
      setLoadingQuestions(false);
      toast.error(err.message);
      setError(err.message)
    }
  }

  async function getQuestions() {
    try {
      setLoadingQuestions(true);
      let res = await axios.get(BASELINE + "questions/apcalc", { params: { id: id } });
      if (res.data.length < 1) {
        toast.error("Questions With these topics not found. Try other topics for now by going back")
      } else {
        start();
      }
      let arr = [res.data]
      let mappedQuestions = arr.map((question, idx) => {
        return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
      });


      setQuestions(mappedQuestions);
      setCurrentQuestion(mappedQuestions[0]);
      setLoadingQuestions(false);
      setans(arr[0].correct_answer);
      setRaion(mappedQuestions[0].rationale);

      if (Array.isArray(arr) && arr.length > 0) {
        if (arr[0].practice_category_primary_section) {
          settopic(arr[0].practice_category_primary_section);
        }
        if (arr[0].skills) {
          setskills(arr[0].skills.split(","));
        }
      }
    } catch (err) {
      setLoadingQuestions(false);
      toast.error(err.message);
      setError(err.message)
    }
  }

  const handleNext = () => {
    const nextId = Number(id) + 1;
    navigate(`/devapcalc/${nextId}`);
  }

  const handleBack = () => {
    const nextId = Number(id) - 1;
    navigate(`/devapcalc/${nextId}`);
  }

  const handleNext2 = () => {
    if (currentQuestion.index < questions.length - 1) {
      setCurrentQuestion(questions[currentQuestion.index + 1]);
    }
  };

  const handleBack2 = () => {
    if (currentQuestion.index > 0) {
      setCurrentQuestion(questions[currentQuestion.index - 1]);
    }
  };



  const handleOptionClick = (option) => {
    if (option === "Pass") {
      const userConfirmed = window.confirm('Are you sure you want to remove this?');

      if (userConfirmed) {
        var data = {
          q_id: q_id,
        };

        axios.post(BASELINE + 'questions/remove/note/byqid', [data])
          .then((response) => {
            alert("Note Removed");
          })
          .catch((error) => {
            //  console.log(error);
          });
      }
    }
    setStatus(option);
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.post(BASELINE + "update/apcalc/questionids", currentQuestion);
      if (response.status === 200) {
        toast.success("Question updated successfully!");
      } else {
        toast.error("Failed to update question.");
      }
    } catch (error) {
      console.error("Error updating question:", error);
      toast.error("Error updating question.");
    }
  };

  const handlerationale = (newContent) => {
    // Update currentQuestion with new content
    setCurrentQuestion((prev) => ({
      ...prev,
      rationale: newContent,
    }));
  };

  const handlestem = (newContent) => {
    // Update currentQuestion with new content
    setCurrentQuestion((prev) => ({
      ...prev,
      stem: newContent,
    }));
  };

  const handleprompt = (newContent) => {
    // Update currentQuestion with new content
    setCurrentQuestion((prev) => ({
      ...prev,
      prompt: newContent,
    }));
  };

  const handlestimulus = (newContent) => {
    // Update currentQuestion with new content
    setCurrentQuestion((prev) => ({
      ...prev,
      stimulus: newContent,
    }));
  };

  return (
    <div className="fbg-white">

      <NavBar
        index={currentQuestion?.index+1}
        questions={questions}
        code={code}
        topics={topics}
        testIdOptions={testname}
        qidOptions={id}
        handleTestIdChange={(value) => console.log('Selected Test ID:', value)}
        handleSubjectChange={(value) => console.log('Selected Subject:', value)}
        handleQidChange={(value) => console.log('Selected QID:', value)}
        currentQuestion={currentQuestion}
        handleNext={handleNext}
        handleBack={handleBack}
        handleNext2={handleNext2}
        handleBack2={handleBack2}
        handleOptionClick={handleOptionClick}
        handleUpdate={handleUpdate}
      />

      {loadingQuestions ?
        <div className="flex flex-row justify-center items-center py-48">
          <div className="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-8 h-52 w-52"></div>
        </div>
        :
        <>
          <div className="flex gap-2 p-2">
            <div className="w-[25%]">
              <p>Title:</p>
              <Select
                value={selectedMainTopic ? { value: selectedMainTopic, label: selectedMainTopic } : null}
                onChange={(selectedOption) => {
                  const value = selectedOption ? selectedOption.value : '';
                  setSelectedMainTopic(value);
                  setSelectedSubTopic('');
                  setSelectedTopic('');
                  setCurrentQuestion((prev) => ({
                    ...prev,
                    category1: value,
                    category2: '',
                    category3: '',
                    sub_code: '', // Reset sub_code when main topic changes
                  }));
                }}
                options={Object.keys(topics).map((topic) => ({ value: topic, label: topic }))}
                className="basic-multi-select lg:min-w-[300px] lg:max-w-[300px]"
                classNamePrefix="select"
              />
            </div>

            <div className="w-[25%]">
              <p>Topic:</p>
              <Select
                value={selectedSubTopic ? { value: selectedSubTopic, label: selectedSubTopic } : null}
                onChange={(selectedOption) => {
                  const value = selectedOption ? selectedOption.value : '';
                  setSelectedSubTopic(value);
                  setSelectedTopic('');
                  setCurrentQuestion((prev) => ({
                    ...prev,
                    category2: value,
                    category3: '',
                    sub_code: '',
                  }));
                }}
                options={
                  selectedMainTopic && topics[selectedMainTopic]
                    ? Object.keys(topics[selectedMainTopic]).map((subTopic) => ({ value: subTopic, label: subTopic }))
                    : []
                }
                isDisabled={!selectedMainTopic}
                className="basic-multi-select lg:min-w-[300px] lg:max-w-[300px]"
                classNamePrefix="select"
              />
            </div>

            <div className="w-[25%]">
              <p>Subject:</p>
              <Select
                value={selectedTopic ? { value: selectedTopic, label: selectedTopic } : null}
                onChange={(selectedOption) => {
                  const value = selectedOption ? selectedOption.value : '';
                  const code = selectedOption ? selectedOption.code : '';
                  setSelectedTopic(value);
                  setCurrentQuestion((prev) => ({
                    ...prev,
                    category3: value,
                    sub_code: code,
                  }));
                }}
                options={
                  selectedMainTopic && selectedSubTopic && topics[selectedMainTopic] && topics[selectedMainTopic][selectedSubTopic]
                    ? topics[selectedMainTopic][selectedSubTopic]["topics"].map((topic) => ({
                      value: topic.title,
                      label: topic.title,
                      code: topic.code,
                    }))
                    : []
                }
                isDisabled={!selectedSubTopic}
                className="basic-multi-select lg:min-w-[300px] lg:max-w-[300px]"
                classNamePrefix="select"
              />
            </div>

            <div className="w-[25%]">
              <p>Code:</p>
              {currentQuestion?.sub_code}
            </div>
          </div>

          {/* question */}

          {currentQuestion ? <>
            <DevQuestion
              ans={ans}
              sat={sat}
              format={format}
              q_id={q_id}
              id={id}
              handleAnswerValueChange={handleAnswerValueChange}
              handleCrossOutOptions={handleCrossOutOptions}
              handleSelectOption={handleSelectOption}
              currentQuestion={currentQuestion}
              idx={1}
              bookmarkedQuestions={bookmarkedQuestions}
              setBookMarkedQuestions={setBookMarkedQuestions}
              handleBookMark={handleBookMark}
            />

            <div className="flex z-[10] mt-[10px]">
              <div className="flex flex-col justify-start w-[50%]">
                <div>
                  <textarea
                    value={currentQuestion.prompt} // Bind to local state
                    onChange={(e) => handleprompt(e.target.value)}
                    className={`w-full ${currentQuestion.prompt ? 'h-[500px]' : 'h-[20px]'} p-2 overflow-y-auto overflow-x-hidden my-list-style border-[1px] border-gray-300`}
                    placeholder="Edit question content here"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2 my-4 w-[50%]">

                <p className="mt-2">Answer A:</p>
                <textarea
                  className="border-[1px] border-gray-300 p-2 w-full"
                  value={currentQuestion.a} // Bind the value to currentQuestion.a
                  onChange={(e) => {
                    const newAnswerA = e.target.value;
                    setCurrentQuestion((prev) => ({
                      ...prev,
                      a: newAnswerA, // Update the answer for A
                    }));
                  }}
                  placeholder="Edit option A"
                />

                <p className="mt-2">Answer B:</p>
                <textarea
                  className="border-[1px] border-gray-300 p-2 w-full"
                  value={currentQuestion.b} // Bind the value to currentQuestion.b
                  onChange={(e) => {
                    const newAnswerB = e.target.value;
                    setCurrentQuestion((prev) => ({
                      ...prev,
                      b: newAnswerB, // Update the answer for B
                    }));
                  }}
                  placeholder="Edit option B"
                />

                <p className="mt-2">Answer C:</p>
                <textarea
                  className="border-[1px] border-gray-300 p-2 w-full"
                  value={currentQuestion.c} // Bind the value to currentQuestion.c
                  onChange={(e) => {
                    const newAnswerC = e.target.value;
                    setCurrentQuestion((prev) => ({
                      ...prev,
                      c: newAnswerC, // Update the answer for C
                    }));
                  }}
                  placeholder="Edit option C"
                />

                <p className="mt-2">Answer D:</p>
                <textarea
                  className="border-[1px] border-gray-300 p-2 w-full"
                  value={currentQuestion.d} // Bind the value to currentQuestion.d
                  onChange={(e) => {
                    const newAnswerD = e.target.value;
                    setCurrentQuestion((prev) => ({
                      ...prev,
                      d: newAnswerD, // Update the answer for D
                    }));
                  }}
                  placeholder="Edit option D"
                />

                <p className="mt-2">Answer E:</p>
                <textarea
                  className="border-[1px] border-gray-300 p-2 w-full"
                  value={currentQuestion.e} // Bind the value to currentQuestion.d
                  onChange={(e) => {
                    const newAnswerD = e.target.value;
                    setCurrentQuestion((prev) => ({
                      ...prev,
                      e: newAnswerD, // Update the answer for D
                    }));
                  }}
                  placeholder="Edit option E"
                />

                <p className="mt-2">Correct Answer:</p>
                <textarea
                  className="border-[1px] border-gray-300 p-2 w-full"
                  value={currentQuestion.answer} // Bind the value to currentQuestion.correct_answer
                  onChange={(e) => {
                    const newAnswer = e.target.value;
                    setCurrentQuestion((prev) => ({
                      ...prev,
                      answer: newAnswer, // Update the correct answer
                    }));
                  }}
                  placeholder="Edit correct answer"
                />
              </div>
            </div>

            <p className="hidden font-bold">Question Rationale</p>
            <div className="hidden ml-[20px] flex z-[10] mt-[10px] gap-2 p-2" id="box-item-left" ref={boxItemLeftRef}>
              <div className="max-w-[50%]">
                <div
                  dangerouslySetInnerHTML={{ __html: currentQuestion.rationale }} // This inserts raw HTML with LaTeX
                  className="min-h-[40%] overflow-y-auto overflow-x-hidden my-list-style"
                />
              </div>

              <div className="w-[50%]">
                <textarea
                  value={currentQuestion.rationale} // Bind to local state
                  onChange={(e) => handlerationale(e.target.value)}
                  className="h-[100%] w-full p-2 overflow-y-auto overflow-x-hidden my-list-style border-[1px] p-2"
                  placeholder="Edit question content here"
                />
              </div>
            </div>
          </> : <></>}

          <div className="flex flex-row justify-around  border-t-2 border-black pt-4 relative">
            {devmode === false && (
              <div className="mb-2 flex flex-row items-center gap-2 text-white">
                <button
                  className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800"
                  onClick={() => window.location.reload()} // Reload the page when clicked
                >
                  Reset
                </button>
                <button className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800"
                  onClick={() => handleUpdate()}
                >Update</button>
                <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800" onClick={handleBack}>
                  Back
                </motion.button>
                {currentQuestion ?
                  <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800" onClick={handleNext}>
                    Next
                  </motion.button>
                  :
                  <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800">
                    Done
                  </motion.button>
                }
              </div>
            )}

          </div>
        </>
      }
    </div >
  );
};
export default DevQuizAPCALC;