import { useEffect, useState, useContext } from "react";
import { BASELINE } from "../../util";
import axios from "axios";
import { UserContext } from "../../context/UserContext";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale } from 'chart.js';
import ModalComponent from "../dashboard/pick_modal";

ChartJS.register(BarElement, CategoryScale, LinearScale);

const Hero = ({ testData, free }) => {
  const { user } = useContext(UserContext);
  const [percentage, setPercentage] = useState(0);
  const [date, setDate] = useState();
  const [missingTopics, setMissingTopics] = useState([]);
  const [testname, setTestname] = useState("");
  const [name, setname] = useState("");
  const [missing_topics, setMissing_topics] = useState([]);
  const [newmissing_topics, setnewMissing_topics] = useState([]);

  const [eng, seteng] = useState(0);
  const [math, setmath] = useState(0);

  const [quizmode, setquizmode] = useState(false);
  const maxScore = 1600;

  const [correctRate, setCorrectRate] = useState("");
  const [incorrectRate, setIncorrectRate] = useState("");
  const [markedRate, setMarkedRate] = useState("");
  const [omittedRate, setOmittedRate] = useState("");
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [topic, settopic] = useState(false);
  const [section, setsection] = useState("");

  const [topics, settopics] = useState([
    { topic: "POW", full: "Production of Wirting" },
    { topic: "KLA", full: "knowledge of Language" },
    { topic: "CSE", full: "Conventions of Standard English" },

    { topic: "IES", full: "Intergrating Essential Skills" },
    { topic: "S", full: "Statistics and Probability" },
    { topic: "A", full: "Algebra" },
    { topic: "G", full: "Geometry" },
    { topic: "F", full: "Functions" },
    { topic: "N", full: "Number and Quantity" },
    { topic: "T", full: "Trigonometry" },

    { topic: "CS", full: "Craft and Structure" },
    { topic: "KID", full: "Key Ideas and Details" },
    { topic: "IKI", full: "Integration of Knowledge and Ideas" },

    { topic: "IOD", full: "Interpretation of Data" },
    { topic: "SIN", full: "Scientific Investigation" },
    { topic: "EMI", full: "Evaluation Models, Inferences, and Exprimental Results" }
  ]);

  const data = {
    labels: [''],
    datasets: [
      {
        label: 'Predicted Score',
        data: [percentage],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
      {
        label: 'Target Score',
        data: [maxScore],
        backgroundColor: 'rgba(201, 203, 207, 0.6)',
      },
    ],
  };

  const options = {
    indexAxis: 'y',
    scales: {
      x: {
        max: maxScore,
        beginAtZero: true,
      },
    },
  };

  const maxScore2 = 100;

  const data2 = {
    labels: [''],
    datasets: [
      {
        label: 'Predicted Score',
        data: [percentage],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
      {
        label: 'Target Score',
        data: [maxScore2],
        backgroundColor: 'rgba(201, 203, 207, 0.6)',
      },
    ],
  };

  const options2 = {
    indexAxis: 'y',
    scales: {
      x: {
        max: maxScore2,
        beginAtZero: true,
      },
    },
  };

  useEffect(() => {
    if (missing_topics && missing_topics.length > 0) {
      console.log("here");
      getSimilarTopics();
    }
  }, [missing_topics]);

  function calculateRate(questionIds, total) {
    if (total === 0) return "0%"; // Avoid division by zero
    const rate = (questionIds.length / total) * 100;
    return `${Math.min(rate, 100).toFixed(0)}%`; // Cap the rate at 100% and format it
  }


  async function sendResult(data) {
    try {
      // Check if `eng_incorrect_questionIds` is defined and parse it safely
      console.log("=>", data);

      var eng_incorrect_questionIds = data.eng_incorrect_questionIds ? JSON.parse(data.eng_incorrect_questionIds) : [];
      console.log("=>", eng_incorrect_questionIds);
      if (data.section_data !== null) {

        // Safely parse the JSON fields
        var Correct_questionIds = data.Correct_questionIds ? safeParseJSON(data.Correct_questionIds) : [];
        var Incorrect_questionIds = data.Incorrect_questionIds ? safeParseJSON(data.Incorrect_questionIds) : [];
        var Marked_questionIds = data.Marked_questionIds ? safeParseJSON(data.Marked_questionIds) : [];
        var Omitted_questionIds = data.Omitted_questionIds ? safeParseJSON(data.Omitted_questionIds) : [];

        // Your existing logic...
        setCorrectRate(calculateRate(Correct_questionIds, 98));
        setIncorrectRate(calculateRate(Incorrect_questionIds, 98));
        setMarkedRate(calculateRate(Marked_questionIds, 98));
        setOmittedRate(calculateRate(Omitted_questionIds, 98));

        setquizmode(true);
        seteng(data.eng_score);
        setmath(data.math_score);
        let newPercentage = Number(data.eng_score) + Number(data.math_score);
        setPercentage(newPercentage);

        var sat = data.section_data ? safeParseJSON(data.section_data) : [];
        let incorrectTopics = sat.filter(s => !s.correct).map(s => s.topic);
        let uniqueIncorrectTopics = [...new Set(incorrectTopics)];
        setMissingTopics([...uniqueIncorrectTopics]);
        console.log("hee");
      } else {
        console.log("hee2");

        var length = JSON.parse(data.quiz_data).length;
        var eng_score = Math.round((JSON.parse(data.Correct_questionIds).length / length) * 100);

        setPercentage(eng_score);
        setquizmode(false);
      }

      let dateObj = new Date(data.date);
      let formattedDate = `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}-${String(dateObj.getDate()).padStart(2, '0')}`;
      setDate(formattedDate);
      setname(data.user_name);

      setTestname(testData.test_name);
      if (eng_incorrect_questionIds.length > 0) {
        setMissing_topics([...eng_incorrect_questionIds]);
      } else {
        let inc = JSON.parse(data.Incorrect_questionIds);
        const uniqueTopics = extractUniqueTopics(inc, data.type);
        setnewMissing_topics([...uniqueTopics]);
      }
    } catch (error) {
      //  console.error("Error in sendResult:", error.message);
    }
  }

  function safeParseJSON(jsonString) {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Invalid JSON:", jsonString, error.message);
      return []; // Return an empty array or handle the error appropriately
    }
  }


  function extractUniqueTopics(questions, type) {
    const uniqueEntries = new Set(); // Use a Set to avoid duplicates
    const topicsWithTags = []; // To store the unique tag-topic pairs

    if (type === 'ACT') {
      questions.forEach(question => {
        const entry = `${question.type}-${question.category}`; // Combine tag and topic
        if (!uniqueEntries.has(entry)) {
          uniqueEntries.add(entry);
          topicsWithTags.push({ type: question.type, category: question.category });
        }
      });

    } else {
      questions.forEach(question => {
        const entry = `${question.tag}-${question.topic}`; // Combine tag and topic
        if (!uniqueEntries.has(entry)) {
          uniqueEntries.add(entry);
          topicsWithTags.push({ tag: question.tag, topic: question.topic });
        }
      });
    }

    return topicsWithTags; // Return array of unique tag-topic pairs
  }


  useEffect(() => {
    console.log(testData);
    if (!free) {
      console.log("Ww");
      sendResult(testData);
    } else {
      console.log("quiz3");

      if (testData.section_data !== null) {
        console.log("quiz");
        const totalScore = parseInt(testData.eng_score, 10) + parseInt(testData.math_score, 10);
        setPercentage(totalScore);
        seteng(testData.eng_score);
        setmath(testData.math_score);
        setquizmode(true);
      } else {
        console.log("quiz2");

        setquizmode(false);
        if (testData && testData.questions) {
          setquizmode(false);
          const totalQuestions = testData.questions.length;
          const correctAnswers = testData.questions.filter(question => question.correct).length;
          const percentage = Math.round((correctAnswers / totalQuestions) * 100);
          setPercentage(percentage);
        }
      }
    }
  }, [testData])

  async function getSimilarTopics() {
    console.log(testData);

    try {
      var res;
      if (testname.includes("89")) {
        res = await axios.get(BASELINE + "topics/89", { params: { itemIds: missing_topics } });
      }
      else if (testname.includes("10")) {
        res = await axios.get(BASELINE + "topics/10", { params: { itemIds: missing_topics } });
      }
      else {
        res = await axios.get(BASELINE + "topics", { params: { itemIds: missing_topics } });
      }
      let uniqueData = [...new Set(res.data)];
      setMissingTopics([...uniqueData]);

    } catch (err) {
      //  console.log(err);
    }

  }
  const capitalizeFirstLetter = (section) => {
    if (!section) return '';  // Check if section is empty or undefined
    return section.charAt(0).toUpperCase() + section.slice(1).toLowerCase();
  }
  const handleRowClick = (subjectName, section) => {
    if (section === 'eng') {
      setsection("English");
    } else if (section === 'ACT') {
      setsection("ACT");
    } else {
      setsection(capitalizeFirstLetter(section));
    }
    settopic(subjectName);
    setIsModalOpen2(true);
    // You can perform any actions here, such as navigation or displaying details
  };

  return (<>
    <ModalComponent topic={topic} isOpen={isModalOpen2} onClose={setIsModalOpen2} section={section} type={testData?.type}/>

    <div className="flex mt-2 justify-center items-center flex-col w-full h-full">
      <section className="wrapper flex flex-col items-center py-10 px-4">
        <div className="pl-6 pr-6 w-full max-w-[1000px] 2xl:max-w-[1300px] bg-white p-8 rounded-2xl shadow-md">
          <h1 className="text-[24px] sm:text-[32px] md:text-[40px] font-bold text-black">
            {user ? (
              <>
                <span className="gradient-text">{user.first_name}</span>'s Analytics Report is Here!
              </>
            ) : (
              <>
                <span className="gradient-text">Welcome User!</span>
              </>
            )}

          </h1>
          <h1 className="text-[24px] sm:text-[32px] md:text-[40px] font-bold text-black text-center sm:text-left">
            {free && (
              <>
                <span className="gradient-text">Free Trial Analytics Report is Here!</span>
              </>
            )}
          </h1>

          <h2 className="text-[20px] sm:text-[24px] md:text-[28px] font-bold text-black">
            Uncover Your Insights Through MMTPrep.
          </h2>
          <p className="text-gray-700 mt-[5px]">
            Our AI-powered test accurately predicts your score and provides a detailed analysis of your strengths and weaknesses based on the questions you’ve answered.
          </p>
          <div className="mt-8">
            <h3 className="text-[18px] sm:text-[22px] md:text-[26px] font-bold text-black">
              Score Analysis
            </h3>
            <div className="mt-4 bg-gray-900 text-white p-6 rounded-lg shadow-md flex flex-col sm:flex-row sm:justify-between sm:items-center">
              <div className="flex flex-col items-center sm:items-start text-center sm:text-left">
                <span className="text-[24px] font-bold">Total Score</span>
                <span className="text-[32px] sm:text-[48px] md:text-[64px] font-bold">
                  {quizmode ? `${percentage} / 1600` : `${percentage} %`}
                </span>
                {free ? (
                  <span className="bg-purple-600 text-white text-[14px] px-2 py-1 rounded-full mt-2">
                    Math Quiz Report
                  </span>
                ) : (
                  <span className="bg-purple-600 text-white text-[14px] px-2 py-1 rounded-full mt-2">
                    {testname} REPORT
                  </span>
                )}
                {quizmode && (
                  <span className="bg-gray-700 text-white text-[14px] px-2 py-1 rounded-full mt-2">
                    SAT Total Score 1600
                  </span>
                )}
              </div>

              <div className="relative mb-4 hidden sm:block">
                {quizmode === true ? (
                  <Bar data={data} options={options} />
                ) : (
                  <Bar data={data2} options={options2} />
                )}
              </div>

              <div className="mt-[-20px] text-sm hidden sm:block">
                {quizmode === true ? (
                  <>
                    <span className="block gradient-text">Your SAT Score</span>
                    <span className="mt-[20px] block text-gray-500">Target Score</span>
                  </>
                ) : (
                  <>
                    <span className="block gradient-text">Your Score</span>
                    <span className="mt-[20px] block text-gray-500">Target Score</span>
                  </>
                )}
              </div>
            </div>

          </div>

          {quizmode === true && (<>
            <div className="w-[100%] mt-4 flex flex-col sm:flex-row justify-around gap-4">
              <GradientBorderBox
                header="SAT Section Score"
                title="Reading & Writing"
                score={eng}
                range="200 - 800"
                description="Upgrade and Develop your skills!"
                icon="🗣️"
              />
              <GradientBorderBox
                header="SAT Section Score"
                title="Math"
                score={math}
                range="200 - 800"
                description="Upgrade and Develop your skills!"
                icon="🚀"
              />
            </div>

            <div className="w-[100%] mt-4 flex flex-col sm:flex-row justify-around gap-4">
              <GradientBorderBox
                header="Overall Performance"
                title="Accuracy Rate"
                score={correctRate}
                range="%"
                description="You're on the right track!"
                icon="✔️"
              />
              <GradientBorderBox
                header="Areas for Improvement"
                title="Error Rate"
                score={incorrectRate}
                range="%"
                description="Review incorrect answers to improve further."
                icon="❌"
              />

              <GradientBorderBox
                header="Review Priority"
                title="Reviewed Questions"
                score={markedRate}
                range="%"
                description="Good effort in revisiting questions!"
                icon="🔖"
              />
              <GradientBorderBox
                header="Opportunities Missed"
                title="Skipped Questions"
                score={omittedRate}
                range="%"
                description="Try to attempt more questions next time to maximize your scoring potential."
                icon="🫙"
              />
            </div>

          </>)}

          <div className="mt-4 bg-gray-900 text-white p-6 rounded-lg shadow-md flex justify-between items-center">
            <div className="flex flex-row justify-between w-full">
              <div className="flex items-center">
                <span className="text-[24px] font-bold">
                  Topic Missing
                </span>
              </div>
              <div className="ml-[200px] flex justify-start items-start flex-col gap-1 min-h-[190px] max-h-[200px] w-full overflow-y-auto ml-auto">

                <div>
                  {[...missing_topics].map((item, index) => (
                    <button
                      key={`${item.tag}-${item.topic}-${index}`}  // Ensures uniqueness by combining tag, topic, and index
                      onClick={() => handleRowClick(item.topic, item.tag)}  // Pass the topic and tag to handleRowClick
                      className="text-white text-[22px] hover:text-[24px] hover:text-purple outline-none focus:text-purple font-normal transition-all text-left block w-full mb-2">
                      {item.topic} {/* Display both tag and topic */}
                    </button>
                  ))}
                  {testData.type === 'ACT' ? (<>
                    {[...newmissing_topics].map((item, index) => {
                      // Find the matching topic in the topics array
                      const matchingTopic = topics.find(topic => topic.topic === item.category);

                      return (
                        <button
                          key={`${item.category}-${item.questionIds}-${index}`}  // Ensures uniqueness by combining tag, topic, and index
                          onClick={() => handleRowClick(item.category, item.type)}  // Pass the topic and tag to handleRowClick
                          className="text-white text-[22px] hover:text-[24px] hover:text-purple outline-none focus:text-purple font-normal transition-all text-left block w-full mb-2">
                          {/* Display the full name if found, otherwise show the category */}
                          {matchingTopic ? matchingTopic.full : item.category}
                        </button>
                      );
                    })}
                  </>) : (<>
                    {[...newmissing_topics].map((item, index) => (
                      <button
                        key={`${item.tag}-${item.topic}-${index}`}  // Ensures uniqueness by combining tag, topic, and index
                        onClick={() => handleRowClick(item.topic, item.tag)}  // Pass the topic and tag to handleRowClick
                        className="text-white text-[22px] hover:text-[24px] hover:text-purple outline-none focus:text-purple font-normal transition-all text-left block w-full mb-2">
                        {item.topic} {/* Display both tag and topic */}
                      </button>
                    ))}
                  </>)}

                </div>

              </div>
            </div>
          </div>

        </div>
      </section >
    </div >
  </>);
};

const GradientBorderBox = ({ header, title, score, range, description, icon }) => {
  return (
    <div className="relative bg-white p-6 rounded-xl shadow-md w-full md:mx-2">
      <div className="absolute inset-0 border-1 rounded-xl border-transparent bg-gradient-to-r from-purple-400 to-blue-400 p-[2px]">
        <div className="h-full w-full bg-white rounded-xl"></div>
      </div>
      <div className="relative">
        <span className="inline-block bg-purple-200 text-purple-700 text-xs font-semibold px-2 py-1 rounded-full">{header}</span>
        <h2 className="text-xl font-bold text-gray-900 mt-2">{title}</h2>
        <div className="flex items-center mt-4">
          {icon && <span className="mr-2">{icon}</span>}
          <span className="text-4xl font-bold text-gray-800">{score}</span>
          <span className="text-xl text-gray-500 ml-2">{range}</span>
        </div>
        <span className="text-gray-500 text-sm">SAT Score</span>
        <p className="text-gray-600 mt-2">{description}</p>
      </div>
    </div>
  );
};

export default Hero;
