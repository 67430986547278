import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { useStopwatch } from "react-timer-hook";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { CiCalculator1, CiPalette } from "react-icons/ci";
import { VscReferences } from "react-icons/vsc";
import { BsThreeDotsVertical, BsExclamationTriangle, BsX } from "react-icons/bs";
import Question from "./MathQuestion";
import axios from "axios";
import Timer from "./Timer";
import { motion, useMotionValue, useTransform } from "framer-motion";
import { UserContext } from "../../context/UserContext";
import { BASELINE } from "../../util";
import Review from "./Review";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import DirectionModal from './DirectionModal';
import ReferenceSheet from './ReferenceSheet';
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import { FaHandBackFist } from "react-icons/fa6";

const ACTQuizfromdashboard = ({ free, freesat }) => {
  const navigate = useNavigate();
  const boxItemLeftRef = useRef();
  const { user } = useContext(UserContext);
  const location = useLocation();
  const responseData = location.state?.data;

  const [questionsarr, setquestionsarr] = useState([]);
  const [timeLeft, setTimeLeft] = useState(null);
  const [section, setsection] = useState(4);
  const [showCalculator, setShowCalculator] = useState(false);
  const [questionOverViewOpen, setQuestionOverviewOpen] = useState(false);
  const [showReference, setShowReference] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [bookmarkedQuestions, setBookMarkedQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [error, setError] = useState('');
  const [showDirectionModal, setShowDirectionModal] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [showDirectionModalRef, setShowDirectionRefModal] = useState(false);
  const [sat, setsat] = useState("");
  const [answers, setanswers] = useState([]);
  const [breaks, setbreaks] = useState(true);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const [test_id, settest_id] = useState(uuidv4());
  const [showOptions, setShowOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [context, setContext] = useState('');

  const [timerActive, setTimerActive] = useState(false);
  const { seconds: stopwatchSeconds, minutes: stopwatchMinutes, start, reset } = useStopwatch({ autoStart: false });
  const displayMinutes = timerActive ? Math.floor(timeLeft / 60) : stopwatchMinutes;
  const displaySeconds = timerActive ? timeLeft % 60 : stopwatchSeconds;

  const [askedConfirmation, setAskedConfirmation] = useState([]);
  const [showanswer, setshowanswer] = useState(false);
  const [result, setResult] = useState([]);
  const [questionTimers, setQuestionTimers] = useState([]);

  async function askforhelp() {
    var data = {
      questionidsarr: questionsarr,
      student_id: user.u_id,
      type: "ACT",
      tag: responseData.tag,
      test_id: test_id
    };

    if (window.confirm("Are you sure you want to get help?")) {
      axios.post(BASELINE + 'post/question/set', [data])
        .then(response => {
          // Handle different responses based on status codes
          if (response.status === 201) {
            window.alert("If you have an assigned teacher, then the teacher will contact you.");
          } else if (response.status === 409) {
            window.alert("A help request with this test ID already exists.");
          } else {
            window.alert("Unexpected response from the server: " + response.status);
          }
        })
        .catch(error => {
          // Handle errors based on the status code returned from the server
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const status = error.response.status;
            if (status === 409) {
              window.alert("A help request with this test ID already exists.");
            } else if (status === 500) {
              window.alert("Internal server error. Please try again later.");
            } else {
              window.alert("An error occurred: " + error.response.status);
            }
          } else if (error.request) {
            // The request was made but no response was received
            window.alert("No response from the server. Please check your network connection.");
          } else {
            window.alert("Error: " + error.message);
          }
        });
    } else {
      window.alert("Help request cancelled.");
    }

  }

  useEffect(() => {
    let timerId;
    if (currentQuestion && result[currentQuestion.index] === undefined) {
      timerId = setInterval(() => {
        setQuestionTimers(prevTimers => {
          const newTimers = [...prevTimers];
          if (newTimers[currentQuestion.index] === undefined) {
            newTimers[currentQuestion.index] = 0;
          }
          newTimers[currentQuestion.index] += 1;
          return newTimers;
        });
      }, 1000); // Update every second
    }

    return () => clearInterval(timerId); // Clear interval when question changes or component unmounts
  }, [currentQuestion, result]);

  const handleSubmit2 = () => {
    const questionId = currentQuestion.qid;
    const reportFrom = user.first_name + " " + user.last_name;

    // Do something with questionId, reportFrom, and context

    var data = {
      questionId: questionId,
      name: reportFrom,
      context: context,
      type: "ACT"
    }

    axios.post(BASELINE + 'questions/report/note', [data])
      .then(response => {
        alert('Report submitted successfully!');
      })
      .catch(error => {
        console.error(error);
      });

    setContext('');
    setShowModal(false);
  };

  const handleContextChange = (event) => {
    setContext(event.target.value);
  };

  const handleReportClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleCloseModal = () => {
    // Clear the context and close the modal
    setContext('');
    setShowModal(false);
  };

  useEffect(() => {
    if (questionsarr.length > 0) {
      getQuestions();
    }
  }, [questionsarr]);

  useEffect(() => {
    var elt = document.getElementById('calculator');
    var calculator = window.Desmos?.GraphingCalculator(elt, {
      graphpaper: true,
      keypad: true
    });
    return () => {
      calculator?.destroy();
    }
  }, [showCalculator]);

  useEffect(() => {
    if (questions.length > 0 && currentQuestion) {
      let q = questions.find((ques => ques?.q_num === currentQuestion?.q_num));
      setCurrentQuestion(q);
    }
  }, [questions]);

  useEffect(() => {
    if (responseData.timedModeActive) {
      // Setup countdown timer
      setTimeLeft(responseData.timeLimit * 60);
      setTimerActive(true);
      const timerInterval = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(timerInterval);  // Clear the interval when time is up
            toast.error("Times Up!");  // Optional: Display a toast notification
            handleSubmit();  // Call submit function when time is up
            return 0;
          }
          return prevTime - 1;  // Decrease the timer by 1 second
        });
      }, 1000);
      setquestionsarr(responseData.questionIds);

      return () => clearInterval(timerInterval);  // Clean up the interval on component unmount
    } else {
      // If not timed mode, use the stopwatch
      reset();
      start();
      setTimerActive(false);
    }
    setquestionsarr(responseData.questionIds);

  }, [responseData]);

  async function getQuestions() {
    try {
      setLoadingQuestions(true);
      let res = await axios.get(BASELINE + "get/act/quiz/by/qids", { params: { qid: questionsarr } });
      let arr = [res.data];
      let mappedQuestions = arr[0].map((question, idx) => {
        return {
          ...question,
          index: idx,
          answered: false,
          bookMarked: false,
          crossedOutOptions: [],
          selectedOption: null
        };
      });

      setQuestions(mappedQuestions);
      setCurrentQuestion(mappedQuestions[0]);
      setLoadingQuestions(false);

      // Initialize askedConfirmation array
      setAskedConfirmation(mappedQuestions.map(() => false));
      setLoadingQuestions(false);

    } catch (err) {
      setLoadingQuestions(false);
      toast.error(err.message);
      setError(err.message);
    }
  }

  async function handleSubmit() {
    try {
      let obtainedMarks = 0;
      let newDat = questions.map((question) => {
        var correctAnswer;

        if (question.answer) {
          try {
            // First, try to parse the string into a JSON array
            correctAnswer = JSON.parse(question.answer);

            // If the result is not an array, convert it to an array
            if (!Array.isArray(correctAnswer)) {
              correctAnswer = [correctAnswer.toString().toLowerCase()];
            } else if (correctAnswer.length === 1) {
              // If there's only one element in the array, convert it to lowercase
              correctAnswer = [correctAnswer[0].toLowerCase()];
            }
          } catch (e) {
            // If JSON.parse fails, treat it as a single answer string
            correctAnswer = [question.answer.toLowerCase()];
          }
        } else {
          correctAnswer = ["No Answer"];
        }

        let isCorrect = false;
        if (Array.isArray(correctAnswer)) {
          for (let i = 0; i < correctAnswer.length; i++) {
            if (String(question.selectedOption).trim() === String(correctAnswer[i]).trim()) {
              isCorrect = true;
              break;
            }
          }
        } else {
          isCorrect = question.selectedOption === correctAnswer;
        }

        if (isCorrect) {
          obtainedMarks++;
          question.correct = true;
        } else {
          question.correct = false;
        }

        const bookMarked = question.bookMarked;
        return {
          answer: question.answer,
          section: question.subject,
          category: question.category,
          bookMarked: bookMarked,
          correct: question.correct,
          selectedOption: question.selectedOption,
          answered: question.answered,
          qid: question.qid,
          tag: question.tag,
          topic: question.skill_descs,
          correctAnswer: question.correct_answer,
          type: 'ACT'
        }
      });

      var test_name = "ACT Practice Test";
      var ori_id = localStorage.getItem('currentTest');

      const correctQuestions = newDat.filter(question => question.correct === true);
      const incorrectQuestions = newDat.filter(question => question.correct === false);
      const bookmarkedQuestions = newDat.filter(question => question.bookMarked === true); // Assuming 'bookMarked' exists
      const nullCorrectAnswerQuestions = newDat.filter(question => question.selectedOption === null);

      let data = {
        Quiz_score: newDat,//all qu
        timeframe: timeLeft,
        userId: user.u_id,
        username: user.first_name + " " + user.last_name,
        test_name: test_name,
        ori_id: ori_id, //can be null
        type: 'ACT',
        test_id: test_id,
        correctQuestions: correctQuestions,
        incorrectQuestions: incorrectQuestions,
        bookmarkedQuestions: bookmarkedQuestions,
        nullCorrectAnswerQuestions: nullCorrectAnswerQuestions,
      }

      localStorage.removeItem('currentTest');
      localStorage.removeItem('testname');
      localStorage.removeItem('type');

      let saveResult = await axios.post(BASELINE + "tests/quiz", data);
      navigate(`/results/act/quiz/${test_id}`);

    } catch (err) {
      toast.error(err.message);
      setError(err.message);
    }


  }

  const handleBookMark = (itemId) => {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.q_num == itemId) {
          if (question.bookMarked) {
            return { ...question, bookMarked: false };
          } else {
            return { ...question, bookMarked: true };
          }
        } else {
          return question;
        }
      })
    }))
  }

  async function handleSelectOption(itemId, option) {
    if (result[currentQuestion.index] !== undefined) {
      return;
    }

    setQuestions(pre => {
      const updatedQuestions = pre.map(question => {
        if (question?.q_num === itemId) {
          return { ...question, selectedOption: option };
        } else {
          return question;
        }
      });

      // Find the updated question
      const updatedQuestion = updatedQuestions.find(question => question.q_num === itemId);
      setCurrentQuestion(updatedQuestion);

      return updatedQuestions;
    });

    // Optionally reset the confirmation state if the user selects an option after confirming
    setAskedConfirmation(prev => {
      const newAsked = [...prev];
      newAsked[currentQuestion.index] = false; // Reset if needed
      return newAsked;
    });
  }

  async function handleCrossOutOptions(itemId, option) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.q_num == itemId) {
          let crossedOutOptions = question.crossedOutOptions;
          if (crossedOutOptions.includes(option)) {
            crossedOutOptions = crossedOutOptions.filter((el) => el !== option);
          } else {
            crossedOutOptions.push(option);
          }
          return { ...question, crossedOutOptions: crossedOutOptions };
        } else {
          return question;
        }
      })
    }))
  }

  const handleAnswerValueChange = (itemId, value) => {
    setQuestions(prevQuestions =>
      prevQuestions.map(question =>
        question.q_num === itemId ? { ...question, selectedOption: value } : question
      )
    );
  };

  function checkAnswer(userInput, correctAnswersJSON) {
    let correctAnswers;

    // Attempt to parse the correct answers JSON
    try {
      correctAnswers = JSON.parse(correctAnswersJSON);
    } catch (e) {
      // If parsing fails, wrap the input as a single answer in an array
      correctAnswers = [correctAnswersJSON];
    }

    // Normalize the correct answers, whether it's a single value or an array
    correctAnswers = Array.isArray(correctAnswers) ? correctAnswers : [correctAnswers];

    correctAnswers = correctAnswers.map(answer => {
      // Normalize each answer: handle numbers and fractions
      if (typeof answer === 'string' && answer.includes('/')) {
        return parseFraction(answer);  // Handle fractions
      } else if (!isNaN(answer)) {
        // Handle integers and floats
        return parseFloat(answer);
      }
      return answer.toString().toLowerCase(); // Handle string answers (e.g., 'A', 'B', etc.)
    });

    // Normalize user input for both numbers and strings
    let normalizedInput;
    if (typeof userInput === 'string' && userInput.includes('/')) {
      normalizedInput = parseFraction(userInput);  // Handle fraction input
    } else if (!isNaN(userInput)) {
      normalizedInput = parseFloat(userInput);  // Handle number input
    } else {
      normalizedInput = userInput.toString().toLowerCase();  // Handle string input (e.g., 'A', 'B', etc.)
    }

    // Check if the normalized user input is in the list of correct answers
    return correctAnswers.includes(normalizedInput);
  }

  function parseFraction(fraction) {
    let [numerator, denominator] = fraction.split('/');
    return denominator ? parseFloat(numerator) / parseFloat(denominator) : parseFloat(numerator);
  }

  async function handleNext() {
    setshowanswer(false);
    const tutorModeActive = responseData?.tutorModeActive || false;

    if (!tutorModeActive) {
      if (currentQuestion.index < questions.length - 1) {
        setCurrentQuestion(questions[currentQuestion.index + 1]);
      } else {
        setCurrentQuestion(null);
        setQuestionOverviewOpen(false);
      }
    } else {
      const currentIdx = currentQuestion.index;

      // Only ask confirmation if not already asked for the current question
      if (!askedConfirmation[currentIdx]) {
        const userResponse = window.confirm(
          "You have not selected an answer. Are you sure you want to proceed without answering?"
        );
        if (!userResponse) {
          // If the user doesn't want to proceed, return early
          return;
        }
        // If the user chooses to proceed, set confirmation as asked for this question
        setAskedConfirmation(prev => {
          const newAsked = [...prev];
          newAsked[currentIdx] = true;
          return newAsked;
        });
      }

      // Check if the selected option is correct
      const isCorrect = checkAnswer(
        currentQuestion.selectedOption,
        currentQuestion.answer
      );

      // Update the result array at the current question's index
      setResult((prevResults) => {
        const newResults = [...prevResults];
        newResults[currentQuestion.index] = isCorrect;
        return newResults;
      });

      // Use the updated result (since setState is async)
      setTimeout(() => {
        if (result[currentIdx] !== undefined) {
          if (currentQuestion.index < questions.length - 1) {
            setCurrentQuestion(questions[currentQuestion.index + 1]);
          } else {
            setCurrentQuestion(null);
            setQuestionOverviewOpen(false);
          }
        }
      }, 0);
    }
  }

  async function handleBack() {
    if (!currentQuestion) {
      setCurrentQuestion(questions[questions.length - 1]);
      return;
    }
    if (currentQuestion.index > 0) {
      setCurrentQuestion((pre) => questions[pre.index - 1])
    } else {

    }
  }

  const closeRefModal = () => {
    setShowDirectionRefModal(false);
  };

  return (
    <div className="min-h-screen bg-white p-1 relative">
      {loadingQuestions ?
        <div className="flex flex-col justify-center items-center py-48">
          <div className="border-t-transparent border-solid animate-spin rounded-full border-blue-400 border-8 h-52 w-52"></div>
          <div className="absolute mt-[-50px]">
            {breaks === true ? <Timer minutes={minutes} seconds={seconds} /> : null}
          </div>
          {breaks === true ?
            <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800 mt-5" onClick={() => { setTimeLeft(-1); }}>
              Resume the test
            </motion.button>
            : null}
        </div>
        :
        <>
          <div className="flex flex-row justify-between">

            <div className="flex flex-row justify-between items-center w-[100%] mt-[20px] pb-[10px] border-b border-gray-200">
              <div className="absolute ml-[20px] flex flex-col">
                <h3>
                  <strong>
                    Section:  {responseData.tag}
                  </strong>
                </h3>
              </div>

              <div className="flex flex-col items-center justify-center flex-grow">
                <div className="flex flex-col sm:flex-row items-center text-center sm:text-left">
                  <Timer minutes={displayMinutes} seconds={displaySeconds} />
                </div>
              </div>

              <div className="z-[20] flex flex-row gap-2 mr-[13px]">
                {user && user?.role !== 'teacher' && (
                  <motion.button
                    whileTap={{ scale: 0.97 }}
                    onClick={() => askforhelp()}
                    className={`mr-[15px] flex flex-col items-center cursor-pointer hover:text-blue-900`}
                  >
                    <div className={`flex flex-row justify-center`}>
                      <FaHandBackFist size="1.5em" />
                    </div>
                    <div>
                      <p className="text-sm">Get Help</p>
                    </div>
                  </motion.button>
                )}
              </div>

              {responseData.tag === "Math" && (<>
                <div className="flex space-x-4">
                  {/* Calculator Button */}
                  <motion.button
                    whileTap={{ scale: 0.97 }}
                    onClick={() => setShowCalculator((prev) => !prev)}
                    className={`mr-4 flex flex-col items-center cursor-pointer hover:text-blue-900 ${showCalculator ? "text-blue-900" : "text-black"
                      }`}
                  >
                    <div className="flex justify-center">
                      <CiCalculator1 size="1.5em" />
                    </div>
                    <div className="mt-1">
                      <p className="text-sm">Calculator</p>
                    </div>
                  </motion.button>

                  {/* Reference Button */}
                  <motion.button
                    whileTap={{ scale: 0.97 }}
                    onClick={() => setShowDirectionRefModal(!showDirectionModalRef)}
                    className={`mr-4 flex flex-col items-center cursor-pointer hover:text-blue-900 ${showReference ? "text-blue-900" : "text-black"
                      }`}
                  >
                    <div className="flex justify-center">
                      <VscReferences size="1.5em" />
                    </div>
                    <div className="mt-1">
                      <p className="text-sm">Reference</p>
                    </div>
                  </motion.button>
                </div>
                {showDirectionModalRef && <ReferenceSheet onClose={closeRefModal} />}
              </>)}

              <div className="ml-[5px] flex flex-col items-center mr-[20px]">
                {!free && (
                  <>
                    <div className="flex flex-col items-center" onClick={() => setShowOptions(!showOptions)}>
                      <BsThreeDotsVertical size="1.5em" />
                      <p className="text-sm mt-1 text-center">More</p>
                    </div>

                    {showOptions && (
                      <div className="fixed mt-[70px] mr-[10px] flex flex-row justify-center cursor-pointer hover:text-red-900 z-[100]" onClick={handleReportClick}>
                        <BsExclamationTriangle size="1.5em" />
                        <p className="text-sm ml-1">Report</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>


            <div className="flex flex-row gap-2">

              <div className="flex flex-col cursor-pointer">
                {showModal && (
                  <div className="fixed z-[20] inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                      </div>
                      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                          <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                Report Card
                              </h3>
                              <div className="mt-2">
                                <form>
                                  <label>Question ID:</label>
                                  <input type="text" className="border p-1 mt-1 w-full" value={currentQuestion.qid} readOnly />
                                  <label>Report from:</label>
                                  <input type="text" className="border p-1 mt-1 w-full" value={user.first_name + " " + user.last_name} readOnly />
                                  <label>Context:</label>
                                  <textarea
                                    className="border p-1 mt-1 w-full"
                                    value={context}
                                    onChange={handleContextChange}
                                  />
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                          <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={handleSubmit2}
                          >
                            Submit
                          </button>
                          <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={handleCloseModal}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

            </div>
          </div>

          <div className="h-[90%]">
            <motion.div drag style={{
              width: '600px',
              height: '400px',
              position: 'absolute',
              zIndex: 100,
              display: showCalculator ? "inline" : "none",
            }}
              dragConstraints={{
                top: -125,
                right: 500,
                bottom: 300,
                left: -125,
              }}
            >
              <div id="calculator" className="w-full h-full">

              </div>

            </motion.div>
            {/* question */}
            <div className="flex flex-col">

              {currentQuestion ?
                <div className="flex flex-col sm:flex-row justify-center h-[50%]">
                  <Question
                    sat={responseData?.tag}
                    handleAnswerValueChange={handleAnswerValueChange}
                    handleCrossOutOptions={handleCrossOutOptions}
                    handleSelectOption={handleSelectOption}
                    currentQuestion={currentQuestion}
                    idx={1}
                    bookmarkedQuestions={bookmarkedQuestions}
                    setBookMarkedQuestions={setBookMarkedQuestions}
                    handleBookMark={handleBookMark}
                    result={result[currentQuestion.index]}
                    time={questionTimers[currentQuestion.index]}
                  />
                </div>
                :
                <div className=" min-h-[50%]">
                  <Review currentQuestion={currentQuestion} setCurrentQuestion={setCurrentQuestion} questions={questions} setQuestions={setQuestions} />
                </div>
              }
            </div>

          </div>

          <div className="z-[300] fixed inset-x-0 bottom-[2px] flex flex-row justify-around border-t-2 border-black pt-4 bg-white">
            {questionOverViewOpen && (
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                  type: "spring",
                }}
                className="absolute z-[3000] rounded-md flex flex-row justify-center p-1 bg-white -top-40 border-[1px] border-gray-300"
              >
                <div className="bg-white min-h-[140px] max-h-[140px] min-w-[200px] max-w-[400px] flex flex-row flex-wrap rounded-md overflow-x-auto overflow-y-auto">
                  {questions.map((ques, idx) => (
                    <div
                      key={'questions-' + idx}
                      onClick={() => setCurrentQuestion(questions[idx])}
                      className={`${ques.bookMarked && 'bg-red-500 border-none text-white'} 
m-1 rounded-full border-[1px] w-[25px] h-[25px] flex flex-row justify-center items-center cursor-pointer 
${currentQuestion?.q_num === ques?.q_num && 'bg-black text-white'}
${ques.selectedOption != null && 'border-none bg-blue-700 text-white'}
border-black`}
                    >
                      {idx + 1}
                    </div>
                  ))}
                </div>
              </motion.div>

            )}

            <div className="flex flex-row items-center z-[999]">
              <p className="font-semibold text-lg">{free ? 'Free Trial' : `${user?.first_name ?? ''} ${user?.last_name ?? ''} `.trim() || 'John Doe'}</p>
            </div>
            {currentQuestion && (
              <motion.button
                whileTap={{ scale: 0.97 }}
                onClick={() => setQuestionOverviewOpen((pre) => !pre)}
                className="flex flex-row items-center gap-1 bg-black hover:bg-gray-800 text-white rounded-md p-2 cursor-pointer"
              >
                <div className="flex flex-row items-center">
                  <p className="text-sm md:text-l">
                    Question {currentQuestion?.index + 1} of {questions?.length}
                  </p>
                </div>
                <div className="flex flex-row items-center">
                  {questionOverViewOpen ? <BsChevronDown /> : <BsChevronUp />}
                </div>
              </motion.button>
            )}
            <div className="flex flex-row items-center gap-2">
              <motion.button
                whileTap={{ scale: 0.97 }}
                className="py-2 px-5 bg-slate-700 rounded-3xl text-white hover:bg-slate-800"
                onClick={() => handleBack()}
              >
                Back
              </motion.button>
              {currentQuestion ? (
                <motion.button
                  whileTap={{ scale: 0.97 }}
                  className="py-2 px-5 bg-slate-700 rounded-3xl text-white hover:bg-slate-800"
                  onClick={() => handleNext()}
                >
                  Next
                </motion.button>
              ) : (
                <motion.button
                  whileTap={{ scale: 0.97 }}
                  className="py-2 px-5 bg-slate-700 rounded-3xl text-white hover:bg-slate-800"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </motion.button>
              )}
            </div>
          </div>

        </>
      }
    </div>
  );
};
export default ACTQuizfromdashboard;