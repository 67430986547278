import { useState, useEffect, useContext, useRef } from "react";
import { useStopwatch } from "react-timer-hook";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { BsThreeDotsVertical } from "react-icons/bs";
import Question from "./MathQuestion";
import axios from "axios";
import { motion, useMotionValue, useTransform } from "framer-motion";
import { UserContext } from "../../context/UserContext";
import { useLocation } from "react-router-dom";
import { BASELINE } from "../../util";
import Review from "./Review";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import NavBar from "./navbar";

const DevACT = () => {
  const {
    seconds,
    minutes,
    start
  } = useStopwatch();

  const { id } = useParams();

  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [questions, setQuestions] = useState([]);
  const [bookmarkedQuestions, setBookMarkedQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [error, setError] = useState('');
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [status, setStatus] = useState(null);
  const [tnum, setTnum] = useState(null);
  const [q_id, setQ_id] = useState(null);
  const [note, setNote] = useState("");
  const [ans, setans] = useState(null);
  const [raion, setRaion] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedSkills, setSelectedSkills] = useState([]);

  const [selectopic, settopic] = useState('');
  const [selectskills, setskills] = useState([]);
  const [format, setformat] = useState(null);

  const [state, setState] = useState(true);
  const [sat, setsat] = useState(true);

  const [devmode, setdevmode] = useState(false);
  const subjectOptions = ['english', 'math', 'reading', 'science'];
  const [testname, setTestname] = useState([]);
  const [options, setOptions] = useState([]); // Store options in state


  const preprocessOptions = (options) => {
    if (Array.isArray(options)) {
      return options;
    }
    try {
      return JSON.parse(options);
    } catch (e) {
      console.error("Failed to parse options:", e);
      return [];
    }
  };  

  useEffect(() => {
    if (currentQuestion) {
      // When currentQuestion is defined, reprocess the options
      const processedOptions = preprocessOptions(currentQuestion?.options);
      setOptions(processedOptions);
    }
  }, [currentQuestion]); // Depend on currentQuestion

  const handleBookMark = (itemId) => {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          if (question.bookMarked) {
            return { ...question, bookMarked: false };
          } else {
            return { ...question, bookMarked: true };
          }
        } else {
          return question;
        }
      })
    }))
  }

  async function getAllNotes(q_id) {
    try {
      let res = await axios.get(BASELINE + "questions/notes", {
        params: {
          q_id: q_id
        }
      });

      if (res.data.length < 1) {
        setStatus("Pass");
        return;
      } else {
        setStatus("Fail");
        setNote(res.data[0]);
      }
    } catch (err) {
      //  console.log(err);
    }
  }

  async function getACTquiz() {
    try {
      let res = await axios.get(BASELINE + "get/act/test", {});
      setTestname(res.data);
    } catch (error) {
      console.error("Error fetching ACT tests:", error);
      toast.error("Failed to load ACT test names.");
    }
  }

  useEffect(() => {
    getACTquiz();

    if (isNaN(id)) {
      setState(true);
      setStatus("Pass");
      setNote("");
      setans(null);
      setSelectedTopic("");
      setSelectedSkills([]);
      settopic("");
      setskills([]);
      getQuestionsbyid();
      setformat(null);
      setdevmode(true);
    } else {
      setState(true);
      setStatus("Pass");
      setNote("");
      setans(null);
      setSelectedTopic("");
      setSelectedSkills([]);
      settopic("");
      setskills([]);
      getQuestions();
      setformat(null);
      setdevmode(false);
    }
  }, [id])

  async function handleSelectOption(itemId, option) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          return { ...question, selectedOption: option };
        } else {
          return question;
        }
      })
    }))
  }
  async function handleCrossOutOptions(itemId, option) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          let crossedOutOptions = question.crossedOutOptions;
          if (crossedOutOptions.includes(option)) {
            crossedOutOptions = crossedOutOptions.filter((el) => el !== option);
          } else {
            crossedOutOptions.push(option);
          }
          return { ...question, crossedOutOptions: crossedOutOptions };
        } else {
          return question;
        }
      })
    }))
  }
  async function handleAnswerValueChange(itemId, value) {
    setQuestions((pre => {
      return pre.map((question) => {
        if (question?.item_id == itemId) {
          return { ...question, selectedOption: value };
        } else {
          return question;
        }
      })
    }))
  }

  async function getQuestionsbyid() {
    try {
      setLoadingQuestions(true);
      let res = await axios.get(BASELINE + "get/act/quiz/byid", { params: { qid: id } });
      if (res.data.length < 1) {
        toast.error("Questions With these topics not found. Try other topics for now by going back")
      } else {
        start();
      }
      let arr = [res.data]
      let mappedQuestions = arr.map((question, idx) => {
        return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
      });

      console.log(arr);

      setQuestions(mappedQuestions);
      setCurrentQuestion(mappedQuestions[0]);
      setLoadingQuestions(false);
      getAllNotes(arr[0].q_num);
      setans(arr[0].answer);
      setRaion(mappedQuestions[0].expl);
      setsat("english");

    } catch (err) {
      setLoadingQuestions(false);
      toast.error(err.message);
      setError(err.message)
    }
  }

  async function getQuestions() {
    try {
      setLoadingQuestions(true);
      let res = await axios.get(BASELINE + "get/act/quiz/byid", { params: { qid: id } });
      if (res.data.length < 1) {
        toast.error("Questions With these topics not found. Try other topics for now by going back")
      } else {
        start();
      }
      let arr = [res.data]
      let mappedQuestions = arr.map((question, idx) => {
        return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
      });

      setsat(arr[0][0].subject);
      setQuestions(mappedQuestions);
      setCurrentQuestion(mappedQuestions[0][0]);
      setLoadingQuestions(false);
      //  getAllNotes(arr[0].q_num);
      setans(arr[0][0].answer);
      setRaion(mappedQuestions[0][0].expl);
      setQ_id(id);

    } catch (err) {
      setLoadingQuestions(false);
      toast.error(err.message);
      setError(err.message)
    }
  }

  const handleNext = () => {
    const nextId = Number(id) + 1;
    navigate(`/devact/${nextId}`);
  }

  const handleBack = () => {
    const nextId = Number(id) - 1;
    navigate(`/devact/${nextId}`);
  }

  const handleContentChange = (newContent) => {
    // Update currentQuestion with new content
    setCurrentQuestion((prev) => ({
      ...prev,
      content: newContent,
    }));
  };

  const handlePromptChange = (newPrompt) => {
    // Update currentQuestion with new prompt
    setCurrentQuestion((prev) => ({
      ...prev,
      prompt: newPrompt,
    }));
  };

  const handleExpl = (newContent) => {
    // Update currentQuestion with new content
    setCurrentQuestion((prev) => ({
      ...prev,
      expl: newContent,
    }));
  };

  const handlep1 = (newContent) => {
    // Update currentQuestion with new content
    setCurrentQuestion((prev) => ({
      ...prev,
      practice_1: newContent,
    }));
  };

  const handlep2 = (newContent) => {
    // Update currentQuestion with new content
    setCurrentQuestion((prev) => ({
      ...prev,
      practice_2: newContent,
    }));
  };

  const handlepsub = (newContent) => {
    // Update currentQuestion with new content
    setCurrentQuestion((prev) => ({
      ...prev,
      practice_sub: newContent,
    }));
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.post(BASELINE + "update/act/qid", currentQuestion);
      if (response.status === 200) {
        toast.success("Question updated successfully!");
      } else {
        toast.error("Failed to update question.");
      }
    } catch (error) {
      console.error("Error updating question:", error);
      toast.error("Error updating question.");
    }
  };

  return (
    <div className="bg-white">

      <NavBar
        testIdOptions={testname}
        subjectOptions={subjectOptions}
        qidOptions={id}
        handleTestIdChange={(value) => console.log('Selected Test ID:', value)}
        handleSubjectChange={(value) => console.log('Selected Subject:', value)}
        handleQidChange={(value) => console.log('Selected QID:', value)}
        currentQuestion={currentQuestion}
        handleNext={handleNext}
        handleBack={handleBack}
      />

      {loadingQuestions ?
        <div className="flex flex-row justify-center items-center py-48">
          <div className="border-t-transparent border-solid animate-spin  rounded-full border-blue-400 border-8 h-52 w-52"></div>
        </div>
        :
        <>
          <div className=" flex flex-row justify-between bg-white">
            <div className="flex flex-row gap-2">
              <div className="hidden flex flex-col cursor-pointer hover:text-blue-900">
                <div className="flex flex-row justify-center">
                  <BsThreeDotsVertical size="1.5em" />
                </div>
                <div>
                  <p className="text-sm">More</p>
                </div>
              </div>

            </div>
          </div>

          <div className="">
            {/* question */}
            <div className="flex flex-col">
              {currentQuestion ?
                <div className="">
                  <div className="flex gap-2 p-2">
                    <div className="w-[33%]">
                      practice_1
                      <textarea
                        value={currentQuestion.practice_1} // Bind to local state
                        onChange={(e) => handlep1(e.target.value)}
                        className="w-full p-2 overflow-y-auto overflow-x-hidden my-list-style border-[1px] border-gray-300 p-2"
                        placeholder="Edit question content here"
                      />
                    </div>
                    <div className="w-[33%]">
                      practice_2
                      <textarea
                        value={currentQuestion.practice_2} // Bind to local state
                        onChange={(e) => handlep2(e.target.value)}
                        className="w-full p-2 overflow-y-auto overflow-x-hidden my-list-style border-[1px] border-gray-300 p-2"
                        placeholder="Edit question content here"
                      />
                    </div>
                    <div className="w-[33%]">
                      practice_sub
                      <textarea
                        value={currentQuestion.practice_sub} // Bind to local state
                        onChange={(e) => handlepsub(e.target.value)}
                        className="w-full p-2 overflow-y-auto overflow-x-hidden my-list-style border-[1px] border-gray-300 p-2"
                        placeholder="Edit question content here"
                      />
                    </div>
                  </div>
                  <Question
                    sat={sat}
                    handleAnswerValueChange={handleAnswerValueChange}
                    handleCrossOutOptions={handleCrossOutOptions}
                    handleSelectOption={handleSelectOption}
                    currentQuestion={currentQuestion}
                    setCurrentQuestion={setCurrentQuestion}
                    idx={1}
                    bookmarkedQuestions={bookmarkedQuestions}
                    setBookMarkedQuestions={setBookMarkedQuestions}
                    handleBookMark={handleBookMark}
                  />

                  <div className="flex z-[10] mt-[10px]">
                    <div className="flex flex-col justify-start w-[50%]">
                      <div>
                        {sat === 'math' ? (
                          <textarea
                            value={currentQuestion.prompt} // Bind to local state
                            onChange={(e) => handlePromptChange(e.target.value)}
                            className="w-full h-[500px] p-2 overflow-y-auto overflow-x-hidden my-list-style border-[1px] border-gray-300 p-2"
                            placeholder="Edit question content here"
                          />

                        ) : (
                          <textarea
                            value={currentQuestion.content} // Bind to local state
                            onChange={(e) => handleContentChange(e.target.value)}
                            className="w-full h-[500px] p-2 overflow-y-auto overflow-x-hidden my-list-style border-[1px] border-gray-300 p-2"
                            placeholder="Edit question content here"
                          />

                        )}

                      </div>
                    </div>

                    <div className="flex flex-col gap-2 my-4 w-[50%]">
                      {options.map((option, idx) => (
                        <textarea
                          key={idx}
                          value={option} // Bind the option value to the textarea
                          onChange={(e) => {
                            const updatedOptions = [...options]; // Copy the existing options array
                            updatedOptions[idx] = e.target.value; // Update the specific option

                            // Update options in state
                            setOptions(updatedOptions);

                            // Update currentQuestion.options with the updated options array
                            setCurrentQuestion((prev) => ({
                              ...prev,
                              options: JSON.stringify(updatedOptions), // Store as a string if options are in JSON format
                            }));
                          }}
                          className="border-[1px] border-gray-300 p-2 w-full"
                          placeholder={`Edit option ${idx + 1}`}
                        />
                      ))}

                      <p className="mt-2">Correct Answer: {currentQuestion.answer}</p>
                      <div>
                        {/* Input to edit the answer */}
                        <textarea
                          className="border-[1px] border-gray-300 p-2 w-full"
                          value={currentQuestion.answer} // Bind the value to currentQuestion.answer
                          onChange={(e) => {
                            const newAnswer = e.target.value;
                            setCurrentQuestion((prev) => ({
                              ...prev,
                              answer: newAnswer, // Update the answer in the currentQuestion state
                            }));
                          }}
                          placeholder="Edit correct answer"
                        />

                        {/* Display the real-time answer */}
                      </div>
                    </div>


                  </div>

                  <p className="font-bold">Question Explnation</p>
                  <div className="ml-[20px] flex z-[10] mt-[10px] gap-2 p-2">
                    <div className="max-w-[50%]">
                      <div
                        dangerouslySetInnerHTML={{ __html: currentQuestion.expl }}
                        className="min-h-[40%] max-h-[420px] overflow-y-auto overflow-x-hidden my-list-style"
                      />
                    </div>

                    <div className="w-[50%]">
                      <textarea
                        value={currentQuestion.expl} // Bind to local state
                        onChange={(e) => handleExpl(e.target.value)}
                        className="w-full p-2 overflow-y-auto overflow-x-hidden my-list-style border-[1px] border-gray-300 p-2"
                        placeholder="Edit question content here"
                      />
                    </div>

                  </div>
                </div>
                : <></>
              }
            </div>

          </div>
          <div className="flex flex-row justify-around  border-t-2 border-black pt-4 relative">


            {devmode === false && (
              <div className="mb-2 flex flex-row items-center gap-2 text-white">
                <button
                  className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800"
                  onClick={() => window.location.reload()} // Reload the page when clicked
                >
                  Reset
                </button>
                <button className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800"
                  onClick={() => handleUpdate()}
                >Update</button>
                <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800" onClick={handleBack}>
                  Back
                </motion.button>
                {currentQuestion ?
                  <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800" onClick={handleNext}>
                    Next
                  </motion.button>
                  :
                  <motion.button whileTap={{ scale: 0.97 }} className="py-2 px-5 bg-blue-700 rounded-3xl text-white hover:bg-blue-800">
                    Done
                  </motion.button>
                }
              </div>
            )}

          </div>
        </>
      }
    </div>
  );
};
export default DevACT;