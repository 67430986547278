import React, { useEffect, useState, useContext, useRef } from "react";
import { BASELINE, AFTER_LOGIN } from "../../util/index";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { toast } from "react-hot-toast";
import Dashboard from "./Index";
import Select from 'react-select'
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import PaginatedTable from "./T_pag_table";
import RandomSATReady from "./Dashboard_SAT";
import GetHelp from "./get_help";
import Modal from 'react-modal'; // Import a modal component like react-modal

const TDashboard = () => {
  const { logoutUser, user } = useContext(UserContext);
  const boxItemLeftRef = useRef();
  const navigate = useNavigate();
  const [className, setClassName] = useState('');
  const [classlst, setclasslst] = useState([]);
  const [studentId, setStudentId] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [table, settable] = useState([]);
  const [isAddStudentOpen, setIsAddStudentOpen] = useState(false);
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [savedquizmodel, setsavedquizmodel] = useState(false);
  const [notes, setnotes] = useState([]);
  const [autosave, setautosave] = useState([]);

  const [quiztitle, setquiztitle] = useState('');
  const [quizgroup, setquizgroup] = useState([]);
  const [quizid, setquizid] = useState('');
  const [quizdata, setquizdata] = useState([]);
  const [quizview, setquizview] = useState(false);
  const [quiz_type, setquiz_type] = useState('');

  const [questionid, setQuestionid] = useState('');
  const [tag, settag] = useState('');
  const [viewmodule, setviewmodule] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [Open, SetOpen] = useState(false);
  const [password, setPassword] = useState('');

  const [sname, setsname] = useState('');
  const [nameSearchTerm, setNameSearchTerm] = useState('');
  const [usersearch, setusersearch] = useState([]);
  const [testDuration, setTestDuration] = useState(0);

  const [hideExplanation, setHideExplanation] = useState(false);
  const [section, setsection] = useState(0);
  const [selectedOption, setSelectedOption] = useState('totalQuestions');
  const [getstudentsscores, setgetstudentsscores] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const studentsPerPage = 5;
  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = table.slice(indexOfFirstStudent, indexOfLastStudent);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [currentPage2, setCurrentPage2] = useState(1);
  const quizzesPerPage = 5;
  const indexOfLastQuiz = currentPage2 * quizzesPerPage;
  const indexOfFirstQuiz = indexOfLastQuiz - quizzesPerPage;
  const currentQuizzes = autosave.slice(indexOfFirstQuiz, indexOfLastQuiz);

  const [folders, setFolders] = useState({});
  const [isAllNotes, setIsAllNotes] = useState(false); // New state to track if "allnotes" is selected

  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0); // Start with the first question
  const [helpsession, sethelpsession] = useState(false);
  const [isPremiumModalOpen, setIsPremiumModalOpen] = useState(false); // State to manage the modal
  const [couponCode, setCouponCode] = useState(""); // State for coupon code
  const [isCouponValid, setIsCouponValid] = useState(null); // State for validation status
  const [isVerifying, setIsVerifying] = useState(false); // State to show loading while verifying
  const [isOpen, setIsOpen] = useState(false);

  const validCouponCodes = ['MMTFREE3MONTHS']; // Replace with actual coupon codes

  const handleFreeTestCreationClick = () => {
    navigate('/freetrial/mathquiz');  // Navigate to the desired path
  };

  const handleVerifyCoupon = async () => {
    setIsVerifying(true); // Start verification loading

    // Simulate an API call to verify the coupon code
    setTimeout(() => {
      if (validCouponCodes.includes(couponCode)) {
        setIsCouponValid(true); // Coupon is valid
        handlePremiumActivation(); // Call function to send the data to the server
      } else {
        setIsCouponValid(false); // Coupon is invalid
      }
      setIsVerifying(false); // End verification loading
    }, 1000); // Simulated delay
  };

  const handlePremiumActivation = () => {
    const today = new Date();
    const startPremiumDate = today.toISOString().split('T')[0]; // Format as YYYY-MM-DD

    const endPremiumDate = new Date();
    endPremiumDate.setMonth(endPremiumDate.getMonth() + 3); // Add 3 months
    const endPremiumDateString = endPremiumDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD

    const premiumData = {
      u_id: user.u_id,
      start_date: startPremiumDate,
      end_date: endPremiumDateString
    };

    // Send the premium data to your API
    axios.post(BASELINE + 'premium/activate', premiumData)
      .then(response => {
        alert("Premium service activated!");
        setIsPremiumModalOpen(false); // Close the modal
        window.location.reload(); // Refresh the website
      })
      .catch(error => {
        console.error(error);
        alert("Error activating premium service.");
      });
  };

  const handleCopyClassId = (id) => {
    navigator.clipboard.writeText(id)
      .then(() => {
        // Notification that the copy was successful
        toast.success('Class ID copied to clipboard!');
      })
      .catch(err => {
        // Notification for any error during the copy process
        toast.error('Failed to copy Class ID');
        console.error('Could not copy text: ', err);
      });
  };

  useEffect(() => {
    if (quizdata.questions && quizdata.questions.length > 0 && currentIndex >= 0) {
      const question = quizdata.questions[currentIndex];
      // Assuming `handleButtonClick` updates the question details like `selectedTopic` or `questionid`
      handleButtonClick(question.questionIds, quizdata, question.section);
    }
  }, [currentIndex, quizdata.questions]); // Add dependencies to ensure useEffect triggers correctly


  const handleNextQuestion = () => {
    if (currentIndex < quizdata.questions.length - 1) { // Check if it's not the last item
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentIndex > 0) { // Check if it's not the first item
      setCurrentIndex(currentIndex - 1);
    }
  };

  const paginate2 = (pageNumber) => setCurrentPage2(pageNumber);

  const fetchPdfs = async () => {
    try {
      const response = await axios.get(`${BASELINE}digital/sat/answers/allnotes`);

      if (Array.isArray(response.data)) {
        setIsAllNotes(true);
        setFolders({ allnotes: response.data });
      } else {
        setIsAllNotes(false);
        setFolders(response.data);
      }
    } catch (error) {
      console.error("Error fetching PDF files:", error);
    }
  };

  useEffect(() => {
    fetchPdfs();
  }, []);

  const get_quiz_view = (testid) => {
    axios.post(BASELINE + 'tests/getstudents', [testid])
      .then(function (response) {
        //  console.log("==", response.data.testId);
        setgetstudentsscores(response.data.testId);
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  const handleClick = (option) => {
    setSelectedOption(option);
  };

  const modulehandleChange = () => {
    setHideExplanation(!hideExplanation);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleNameSearchChange = (event) => {
    setNameSearchTerm(event.target.value);

    axios.get(`${BASELINE}user/search/name/${event.target.value}`)
      .then(function (response) {
        setusersearch(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleSubmit2 = () => {
    if (password.length < 6) {
      alert("Password must be at least 6 characters long.");
      return;
    }

    axios.post(BASELINE + 'user/setpassword', [sname, password])
      .then(function (response) {
        setPassword('');
        setsname('');
        setNameSearchTerm('');
        alert("Password Reset Successfully!");
      })
      .catch(function (error) {
        alert("Something Went Wrong.");
      });

    SetOpen(false);
  };


  function logout() {
    logoutUser();
    axios.post(BASELINE + 'user/logout', {})
      .then(function (response) {
        alert(response.data.message);
        window.location.replace(AFTER_LOGIN);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  useEffect(() => {
    // Dynamically load the KaTeX script
    const script2 = document.createElement('script');
    script2.src = 'https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js';
    script2.integrity = 'sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05';
    script2.crossOrigin = 'anonymous';

    // Handle script load
    script2.onload = () => {
      if (boxItemLeftRef.current) {
        const options = {
          delimiters: [
            { left: '$$', right: '$$', display: false },
            { left: "$", right: "$", display: false },
            { left: '\\(', right: '\\)', display: false },
            { left: '\\[', right: '\\]', display: true },
            { left: '\\begin{array}', right: '\\end{array}', display: true }
          ],
          throwOnError: false,
        };


        window.renderMathInElement(boxItemLeftRef.current, options);
      }
    };
    document.body.appendChild(script2);

    // Cleanup: remove the script when component unmounts
    return () => {
      document.body.removeChild(script2);
    };
  }, [selectedTopic, hideExplanation]);

  function fetchAutoSaveData() {
    if (user) {
      setautosave([]);
      axios.get(BASELINE + 'getautosave', { params: { id: user.u_id } })
        .then(response => {
          let jsonData = response.data.data;

          setautosave(response.data.data);
        })
        .catch(error => {
          // Handle the error if needed
          console.error(error);
        });
    }
  }

  useEffect(() => {
    if (!user || user.role !== 'teacher') {
      navigate('/');
    }
    fetchAutoSaveData();
  }, [user, navigate]);

  useEffect(() => {
    if (user) {
      axios.post(BASELINE + 'getclass', { params: { teacher_id: user.u_id } })
        .then(function (response) {
          setclasslst(response.data);
        })
        .catch(function (error) {
          //  console.log(error);
        });
    }
  }, [className]);

  useEffect(() => {
    if (selectedClass.classid) {
      var data = {
        classid: selectedClass.classid
      }

      axios.post(BASELINE + 'getclassbyid', [data])
        .then(function (response) {

          if (response.data[0].classdata != null) {
            console.log(response.data[0].classdata);

            settable(response.data[0].classdata);
          } else {
            settable([]);
          }
        })
        .catch(function (error) {
          //  console.log(error);
        });
    }
  }, [selectedClass]);

  const generateShortId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };


  const handleCreateClass = (event) => {
    event.preventDefault();
    const shortId = generateShortId(); // Generate a short ID
    var data = {
      teacher_id: user.u_id,
      class_name: className,
      shortid: shortId,  // Include the generated ID in your data
      premium: user.premium
    };

    axios.post(BASELINE + 'class', [data])
      .then(function (response) {
        setClassName('');
        toast.success(`Class created with ID: ${shortId} - ${response.data.message}`);
      })
      .catch(function (error) {
        // Check if the error message is related to non-premium user limit
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(`Failed to create class: ${error.response.data.message}`);
        } else {
          toast.error('Failed to create class. Please try again.');
        }
      });
  };

  const handleChange = (event) => {
    setStudentId(event.target.value);
  };

  const handleSelectChange = (event) => {
    if (event.target.value.length > 10) {
      setSelectedClass(JSON.parse(event.target.value));
    }
  };

  const passwordhandleSubmit = () => {
    if (password.length < 6) {
      alert("Password must be at least 6 characters long.");
      return;
    }

    axios.post(BASELINE + 'user/setpassword', [user.u_id, password])
      .then(function (response) {
        setPassword('');
        alert("Password Reset Successfully!");
      })
      .catch(function (error) {
        alert("Something Went Wrong.");
      });

    setIsOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!selectedClass.classid) {
      alert('Please select a class first.');
      return;
    } else {

      var data = {
        classid: selectedClass.classid,
        studentid: studentId
      }

      axios.post(BASELINE + 'classaddstudent', [data])
        .then(function (response) {
          setStudentId('');
          if (response.data.data) {
            settable(response.data.data);
          }
          alert(response.data.message);
        })
        .catch(function (error) {
          //  console.log(error);
        });
    }
  };

  const handleRemove = (studentId) => {
    const confirmDelete = window.confirm('Are you sure you want to remove this student from the class?');

    if (confirmDelete) {
      var data = {
        classid: selectedClass.classid,
        studentid: studentId
      }

      axios.post(BASELINE + 'classdelstudent', [data])
        .then(function (response) {
          setStudentId('');
          if (response.data.data) {
            settable(response.data.data);
          }
          alert(response.data.message);
        })
        .catch(function (error) {
          //  console.log(error);
        });
    }
  }

  const handleViewDashboard = (studentId) => {
    setSelectedStudentId(studentId);
    setIsDashboardOpen(true);
  }

  const handleClose = () => {
    setIsAddStudentOpen(false);
    setIsDashboardOpen(false);
  }

  const handleRemoveClass = () => {
    if (window.confirm('Are you sure you want to remove this class?')) {
      var data = {
        classid: selectedClass.classid,
        owner: user.u_id,
      }

      axios.post(BASELINE + 'classdel', [data])
        .then(function (response) {
          setStudentId('');
          settable([]);
          setclasslst(response.data);
        })
        .catch(function (error) {
          //  console.log(error);
        });
    }
  };

  const getallnotes = () => {
    axios.get(BASELINE + 'questions/allnotes', [])
      .then(function (response) {
        setnotes(response.data);
      })
      .catch(function (error) {
        //  console.log(error);
      });
  };

  useEffect(() => {
    getallnotes();
  }, []);

  const handleviewproblem = (type, question_id) => {
    if (type === 'SAT') {
      navigate(`/devpsat/${question_id}`);
    } else if (type === 'ACT') {
      navigate(`/devact/${question_id}`);
    }
  }

  const handleremovenote = async (id) => {
    try {
      const response = await axios.post(BASELINE + 'questions/remove/note/byqid', [{ q_id: id }]);
      console.log(response.data.message);
      getallnotes();

      toast.success("노트가 성공적으로 제거되었습니다.");
    } catch (error) {
      console.error("노트 제거 중 오류 발생:", error);
      toast.error("노트 제거에 실패했습니다.");
    }
  };

  function handleSave() {
    const updatedQuizData = {
      ...quizdata,
      quizgroup: quizgroup,
      testname: quiztitle,
    };

    axios.post(BASELINE + 'updateaudosavebyid', { data: JSON.stringify(updatedQuizData), test_id: quizid })
      .then(response => {
        toast.success('Quiz updated successfully');
        fetchAutoSaveData();

      })
      .catch(error => {
        // Handle the error if needed
        console.error(error);
      });
    setsavedquizmodel(false);
  }

  const handleButtonClick = async (questionid, data, tag) => {
    console.log(data);
    setQuestionid(questionid);
    let section;
    console.log(tag);
    if (tag === "English") {
      section = "Eng"
    } else {
      section = "Math"
    }

    settag(section);

    if (data.type === 'SAT') {
      try {
        let res = await axios.get(BASELINE + "questions/sat", { params: { id: [questionid], section: section } });
        let arr = [res.data]
        let mappedQuestions = arr[0].map((question, idx) => {
          return { ...question, index: idx, answered: false, bookMarked: false, crossedOutOptions: [], selectedOption: null };
        });

        setSelectedTopic(mappedQuestions[0]);

      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }

    }

    setviewmodule(true);
  };

  const handleRemoveQuiz = () => {
    if (window.confirm('Are you sure you want to remove this quiz?')) {
      var data = {
        quizid: quizid
      }

      axios.post(BASELINE + 'removeautosavequiz', { data: data })
        .then(response => {
          toast.success('Quiz removed successfully');
          setsavedquizmodel(false);
          fetchAutoSaveData();
        })
        .catch(error => {
          // Handle the error if needed
          console.error(error);
        });
    }
  };

  const handleNavigate = (parsedData) => {
    if (parsedData) {
      if (window.confirm('Are you sure you want to navigate to another page?')) {
        localStorage.setItem('currentTest', parsedData);
        localStorage.setItem('type', quiz_type);

        navigate(`/sat/quiz`);
      }
    }
  };

  return (<>

    <div className="w-[250px] h-full shadow-md bg-white absolute">
      <div className="p-5 text-xl font-bold border-b">
        Teacher Dashboard
        <p>
          {user && user.premium === 1 ? (<div className="mt-[5px] text-sm font-bold gradient-text">
            Membership: Premium
          </div>) : (<div className="mt-[5px] text-sm font-bold">
            Membership: Free Trial
          </div>)}
        </p>

      </div>
      <ul className="py-4 !text-black pl-4 md:block">
        <li className="p-2 font-bold text-xl md:text-xl">My Class</li>
        <li className="p-2 cursor-pointer hover:bg-gray-100"><button onClick={() => setsection(0)}>Study Class</button></li>
        <li className="p-2 cursor-pointer hover:bg-gray-100"><button onClick={() => setsection(3)}>Test Creation</button></li>
        {user?.email === 'seank@admin.com' && (
          <>
            <li><button onClick={() => SetOpen(true)} className="block p-4 hover:bg-gray-100">User Management</button></li>
            <li><button onClick={() => setsection(1)} className="block p-4 hover:bg-gray-100">Reported Problem</button></li>
            <li><button onClick={() => setsection(2)} className="block p-4 hover:bg-gray-100">SAT Answers</button></li>
          </>
        )}
        {user && user?.premium ? (<>
          <li className="mt-[100px] p-2 cursor-pointer hover:bg-gray-100" onClick={() => setIsOpen(true)}>Password Reset</li>
          <li className="p-2 cursor-pointer hover:bg-gray-100"><button onClick={() => logout()} className="text-red-900 font-bold">Logout</button></li>
        </>) : (
          <>
            <li className="p-2 cursor-pointer hover:bg-gray-100" onClick={handleFreeTestCreationClick}>[FREE] Test Creation</li>
            <li className="mt-[100px] p-2 cursor-pointer gradient-text font-bold hover:bg-gray-100" onClick={() => setIsPremiumModalOpen(true)}>Go Premium</li>
            <li className="p-2 cursor-pointer hover:bg-gray-100" onClick={() => setIsOpen(true)}>Password Reset</li>
            <li className="p-2 cursor-pointer hover:bg-gray-100"><button onClick={() => logout()} className="text-red-900 font-bold">Logout</button></li>
          </>
        )}

      </ul >
    </div >

    <div className="bg-white ml-[280px] mr-[2%] md:flex-row">

      {Open && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all ml-[20%] mr-[20%] mt-[20%]">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      User Management
                    </h3>

                    <div className="mt-2 flex">
                      <h4 className="mr-2 mt-2">Search</h4>
                      <input
                        type="text"
                        placeholder="Search by name"
                        value={nameSearchTerm}
                        onChange={handleNameSearchChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />

                    </div>

                    <table className="mt-2 table-auto">
                      <thead>
                        <tr>
                          <th className="px-4 py-2">First Name</th>
                          <th className="px-4 py-2">Last Name</th>
                          <th className="px-4 py-2">Email</th>
                          <th className="px-4 py-2">User ID</th>
                          <th className="px-4 py-2">Select</th>

                        </tr>
                      </thead>
                      <tbody>
                        {usersearch.map((user, index) => (
                          <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                            <td className="border px-4 py-2">{user.first_name}</td>
                            <td className="border px-4 py-2">{user.last_name}</td>
                            <td className="border px-4 py-2">{user.email}</td>
                            <td className="border px-4 py-2">{user.u_id}</td>
                            <td className="border px-4 py-2">
                              <button onClick={() => setsname(user.u_id)}>Select</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {sname != '' && (
                      <div className="mt-2 flex">
                        <input type="password" value={password} onChange={handlePasswordChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="password" placeholder="New Password" />
                        <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={handleSubmit2}>
                          Change
                        </button>
                      </div>
                    )}

                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" onClick={() => { SetOpen(false); setsname(''); setNameSearchTerm(''); setusersearch([]); }}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {section === 0 && (
        <>
          <div className="pt-6">
            <div className="flex border-b">
              <h2 className="text-2xl font-bold text-slate-900 p-2">Study Class</h2>
              <button className="bg-slate-700 p-2 m-1 text-sm text-white rounded-xl" onClick={() => setIsModalOpen(true)}>Add Class</button>
            </div>

            <div className="flex">
              <select
                id="classSelect"
                className="m-2 rounded text-l border"
                value={JSON.stringify(selectedClass)}
                onChange={handleSelectChange}
              >
                <option value="">Select the Class</option>
                {classlst.map((classItem, index) => (
                  <option key={index} value={JSON.stringify(classItem)}>
                    {classItem.class_name}
                  </option>
                ))}
              </select>

              {selectedClass.class_name && (
                <button className="m-2 p-[3px] text-xl text-red-700" onClick={handleRemoveClass}>Remove</button>
              )}

            </div>
          </div>

          <div className="m-2 w-full md:mr-[5px] overflow-y-auto">
            {selectedClass.class_name && (
              <div
                className="w-full p-4 bg-slate-800 rounded relative border-4 border-dashed border-gray-500 flex items-center justify-center mb-4"
                key="add-student"
              >
                <div
                  className="text-white text-2xl font-bold flex items-center justify-center cursor-pointer"
                  onClick={() => handleCopyClassId(selectedClass.shortid)}
                >
                  Class ID: {selectedClass.shortid}
                </div>

              </div>
            )}

            {currentStudents.length > 0 ? (
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="py-2">Student ID</th>
                    <th className="py-2">Name</th>
                    <th className="py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentStudents.map((student, index) => (
                    <tr key={index} className="bg-gray-100 border-b">
                      <td className="py-2 px-6 text-center relative">
                        {student.u_id}
                      </td>
                      <td className="py-2 px-6 text-center">{student.first_name} {student.last_name}</td>
                      <td className="py-2 px-6 text-center">
                        <button
                          className="bg-red-200 text-red-500 font-bold py-[3px] px-4 rounded mr-[10px]"
                          onClick={() => handleRemove(student.u_id)}
                        >
                          Remove
                        </button>

                        <button
                          className="bg-gray-300 text-gray-800 font-bold py-[3px] px-4 rounded mr-[10px]"
                          onClick={() => { handleViewDashboard(student.u_id); setsection(6) }}
                        >
                          View Dashboard
                        </button>

                        <button
                          className="bg-blue-300 text-blue-800 font-bold py-[3px] px-4 rounded"
                          onClick={() => { setsection(7); sethelpsession(true); setSelectedStudentId(student.u_id); }}
                        >
                          Help Session
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>No students available</div>
            )}

            {/* Pagination */}
            <div className="flex justify-center mt-4">
              {Array.from({ length: Math.ceil(table.length / studentsPerPage) }, (_, i) => (
                <button
                  key={i}
                  onClick={() => paginate(i + 1)}
                  className={`mx-1 px-3 py-1 rounded ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </div>

          <div className="mt-[5px] rounded">
            <h2 className="text-2xl font-bold text-slate-900 p-2 border-b">Saved Test</h2>
          </div>

          <div className="overflow-y-auto mb-4">
            {autosave.length > 0 ? (
              <>
                <table className="min-w-full bg-white">
                  <thead>
                    <tr>
                      <th className="py-2">Quiz Name</th>
                      <th className="py-2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentQuizzes.map((test, index) => {
                      let parsedData = JSON.parse(test.data);
                      return (
                        <tr key={index} className="bg-gray-100 border-b">
                          <td className="px-6 text-center relative">
                            {parsedData.testname}
                          </td>
                          <td className="px-6 text-center">
                            <button
                              className="bg-gray-200 text-black rounded m-2 p-2"
                              onClick={() => {
                                setsavedquizmodel(true);
                                setquiztitle(parsedData.testname);
                                setquizid(test.testid);
                                setquizdata(parsedData);
                                setquizgroup(parsedData.quizgroup);
                                setquiz_type(parsedData.test_name);
                              }}
                            >
                              Setting
                            </button>
                            <button
                              className="bg-gray-200 text-black rounded m-2 p-2"
                              onClick={() => {
                                setsection(4);
                                setquizview(true);
                                setquiztitle(parsedData.testname);
                                setquizid(test.testid);
                                setquizdata(parsedData);
                                setquizgroup(parsedData.quizgroup);
                                setquiz_type(parsedData.test_name);
                                get_quiz_view(parsedData.test_id);
                              }}
                            >
                              View
                            </button>
                            <button
                              className="bg-gray-200 text-black rounded m-2 p-2"
                              onClick={() => {
                                setquiz_type(parsedData.test_name);
                                handleNavigate(test.testid);
                              }}
                            >
                              Resume
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                {/* Pagination */}
                <div className="flex justify-center mt-4">
                  {Array.from({ length: Math.ceil(autosave.length / quizzesPerPage) }, (_, i) => (
                    <button
                      key={i}
                      onClick={() => paginate2(i + 1)}
                      className={`mx-1 px-3 py-1 rounded ${currentPage2 === i + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'}`}
                    >
                      {i + 1}
                    </button>
                  ))}
                </div>
              </>
            ) : (
              <div>Empty Test</div>
            )}
          </div>
        </>
      )}

      {section === 1 && (
        <>
          <div className="mt-[30px] rounded">
            <h2 className="text-2xl font-bold text-slate-900 p-2 border-b">Reported Problem</h2>
          </div>

          <div className="m-2 w-full md:mr-[5px] overflow-y-auto">
            {notes.length > 0 ? (
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="py-2">Note ID</th>
                    <th className="py-2">Type</th>
                    <th className="py-2">Question ID</th>
                    <th className="py-2">Submitter</th>
                    <th className="py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {notes.map((note, index) => {
                    console.log(note); // This will log each note to the console
                    return (
                      <tr key={index} className="bg-gray-100 border-b">
                        <td className="py-2 px-6 text-center">{note.id}</td>
                        <td className="py-2 px-6 text-center">{note.note.type}</td>
                        <td className="py-2 px-6 text-center">{note.question_id}</td>
                        <td className="py-2 px-6 text-center">{note.note.name}</td>
                        <td className="py-2 px-6 text-center">
                          <button
                            className="bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
                            onClick={() => handleviewproblem(note.note.type, note.question_id)}
                          >
                            View Problem
                          </button>

                          <button
                            className="ml-2 bg-red-300 text-gray-800 font-bold py-2 px-4 rounded"
                            onClick={() => handleremovenote(note.id)}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div>No notes available</div>
            )}
          </div>
        </>
      )}

      {section === 2 && (
        <>
          <div className="m-2 w-full md:mr-[5px] overflow-y-auto">
            <PaginatedTable
              folders={folders}
              setFolders={setFolders}
              fetchPdfs={fetchPdfs}
            />
          </div>
        </>
      )}

      {section === 3 && (
        <RandomSATReady className="ml-[250px]" setLoading={setLoading} />
      )}

      {section === 4 && quizview && (
        <div className="p-2 pt-[40px] bg-white">
          <div className="">
            <div className="">
              <div className="bg-white">
                <button
                  className="absolute top-[10px] right-[10px] bg-slate-700 text-white p-2 rounded-xl flex items-center justify-center"
                  onClick={() => { setsection(0); setquizview(false); setSelectedOption("totalQuestions"); }}
                >
                  Back
                </button>
                <h2 className="mt-[10px] text-2xl font-bold mb-2">{quiztitle}: Overview</h2>

                <div className="flex justify-center items-center bg-blue-100 py-4 mb-4">
                  <div
                    className={`text-center px-8 cursor-pointer ${selectedOption === 'totalQuestions' ? 'underline' : ''}`}
                    onClick={() => handleClick('totalQuestions')}
                  >
                    <p className="text-sm font-medium">Total Questions</p>
                  </div>
                  <div
                    className={`text-center px-8 cursor-pointer ${selectedOption === 'scores' ? 'underline' : ''}`}
                    onClick={() => handleClick('scores')}
                  >
                    <p className="text-sm font-medium">Scores</p>
                  </div>
                </div>

                {selectedOption == 'totalQuestions' ? (<>
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr className="w-full bg-gray-200 text-left">
                        <th className="px-4 py-2">Question Id</th>
                        <th className="px-4 py-2">Skills</th>
                        <th className="px-4 py-2">Correct Answers</th>
                        <th className="px-4 py-2">View</th>
                      </tr>
                    </thead>
                    <tbody>
                      {quizdata.questions.map((questionObj, index) => {
                        return (
                          <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                            <td className="px-4 py-2">{index + 1}</td>
                            <td className="px-4 py-2">{questionObj.skill_descs}</td>
                            <td className="px-4 py-2">{questionObj.correct_answer}</td>
                            <td className="px-4 py-2">
                              <button
                                className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                onClick={() => {
                                  setsection(5);
                                  handleButtonClick(quizdata.questions[currentIndex].questionIds, quizdata, quizdata.questions[currentIndex].section);
                                }}
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </>) : (<>
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr className="w-full bg-gray-200 text-left">
                        <th className="px-4 py-2">Student Name</th>
                        <th className="px-4 py-2">Class</th>
                        <th className="px-4 py-2">Score</th>
                        <th className="px-4 py-2">View</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getstudentsscores.map((questionObj, index) => {
                        const engCorrect = JSON.parse(questionObj.eng_correct_questionIds || "[]").length;
                        const engIncorrect = JSON.parse(questionObj.eng_incorrect_questionIds || "[]").length;

                        const engScore = engCorrect + engIncorrect > 0 ? Math.round((engCorrect / (engCorrect + engIncorrect)) * 100) : 0;

                        return (
                          <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                            <td className="px-4 py-2">{questionObj.user_name}</td>
                            <td className="px-4 py-2">{questionObj.test_name}</td>
                            <td className="px-4 py-2">{engScore | "Not Ready"}</td>
                            <td className="px-4 py-2">
                              <button
                                className="text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                onClick={() => { setSelectedStudentId(questionObj.user_id); setIsDashboardOpen(true) }}
                              >
                                View
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>)}


                <div className="mt-[10px] rounded p-2 text-lg mx-auto block overflow-auto h-36">
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        isOpen={isPremiumModalOpen}
        onRequestClose={() => setIsPremiumModalOpen(false)}
        contentLabel="Go Premium Modal"
        className="absolute bg-white p-6 rounded-lg shadow-md max-w-lg mx-auto mt-12"
        overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center"
      >
        <h2 className="text-lg font-bold mb-4">Go Premium</h2>
        <p>Enter your coupon code to activate premium service:</p>
        <div className="mt-4">
          <input
            type="text"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            className="border p-2 rounded w-full"
            placeholder="Enter Coupon Code"
          />
        </div>
        <div className="mt-2 text-sm text-gray-600">
          <p>Need a code? Contact <a href="mailto:angiek@mmtprep.com" className="text-blue-500">angiek@mmtprep.com</a> for assistance.</p>
        </div>
        {isCouponValid !== null && (
          <div className={`mt-2 ${isCouponValid ? 'text-green-500' : 'text-red-500'}`}>
            {isCouponValid ? 'Coupon code is valid!' : 'Invalid coupon code.'}
          </div>
        )}
        <div className="flex justify-end mt-4">
          <button
            onClick={handleVerifyCoupon}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            disabled={isVerifying}
          >
            {isVerifying ? 'Verifying...' : 'Verify Coupon'}
          </button>
          <button
            onClick={() => setIsPremiumModalOpen(false)}
            className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Cancel
          </button>
        </div>
      </Modal>

      {isAddStudentOpen && (
        <div className="hidden fixed z-10 inset-0 overflow-y-auto  mt-[10%]">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[40%]">
              <div className="bg-white flex-1 p-3 border border-gray-300 rounded relative">
                <button
                  className="absolute top-0 right-0 text-red-500 text-2xl font-bold flex items-center justify-center mr-[10px]"
                  onClick={handleClose}
                >
                  X
                </button>
                <h2 className="text-2xl font-bold">Class Title: {selectedClass.class_name}</h2>
                <form>
                  <div className="flex items-center justify-center space-x-2">
                    <label htmlFor="studentId" className="text-lg font-bold">Student ID:</label>
                    <input
                      type="text"
                      id="studentId"
                      name="studentId"
                      className="border border-gray-300 rounded p-2 text-lg"
                      value={studentId}
                      onChange={handleChange}
                    />
                    <button
                      type="submit"
                      className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
                      onClick={handleSubmit}
                    >
                      Add Student
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {section === 6 && isDashboardOpen && (
        <div className="pt-6 bg-white">
          <div className="ml-[-250px]">
            <button
              className="absolute top-0 right-0 text-white p-2 rounded-xl mt-[10px] text-md bg-slate-700 flex items-center justify-center mr-[10px]"
              onClick={() => { handleClose(); setsection(0); }}
            >
              Back
            </button>
            <Dashboard s_uid={selectedStudentId} smallmode={true} />
          </div>
        </div>

      )}

      {section === 7 && helpsession && (
        <div className="bg-white">
          <div className="ml-[-250px]">
            <button
              className="absolute top-0 right-0 text-white p-2 rounded-xl mt-[10px] text-md bg-slate-700 flex items-center justify-center mr-[10px]"
              onClick={() => { handleClose(); setsection(0); }}
            >
              Back
            </button>
            <div className="ml-[200px]">
              <GetHelp studentid={selectedStudentId} />
            </div>
          </div>
        </div>

      )}

      {isModalOpen && (
        <div className="mt-[100px] fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white flex-1 p-4 border border-gray-300 rounded">
                <button className="absolute top-0 right-0 m-2" onClick={() => setIsModalOpen(false)}>Close</button>
                <h2 className="text-2xl font-bold">Create the Class</h2>
                <form onSubmit={handleCreateClass}>
                  <label className="text-xl" htmlFor="className">Class Name: </label>
                  <input type="text" id="className" name="className" className="ml-[10px] border border-gray-300 rounded p-2 text-lg" value={className} onChange={(e) => setClassName(e.target.value)} />
                  <button type="submit" className="mt-[10px] bg-blue-500 text-white font-bold py-2 px-4 rounded mx-auto block">Create</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {savedquizmodel && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-40 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Change Test Name
                    </h3>
                    <div>
                      <input
                        type="text"
                        id="className"
                        name="className"
                        className="ml-[10px] border border-gray-300 rounded p-2 text-lg"
                        value={quiztitle}
                        onChange={e => setquiztitle(e.target.value)}
                      />
                    </div>
                    <div className="hidden text-sm leading-6 font-medium text-gray-900" id="modal-title">
                      [NOT WORKING] Enter Test Duration (in seconds). Input '0' for no time limit.
                    </div>
                    <div>
                      <input
                        type="number"
                        id="testDuration"
                        name="testDuration"
                        className="hidden ml-[10px] border border-gray-300 rounded p-2 text-lg"
                        value={testDuration}
                        onChange={e => setTestDuration(e.target.value)}
                      />
                    </div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900 mt-4" id="modal-title">
                      Assign Quiz to Class (multiple select)
                    </h3>
                    <div>
                      <Select
                        isMulti
                        options={classlst}
                        getOptionLabel={(option) => option.class_name}
                        getOptionValue={(option) => option.class_name}
                        value={quizgroup}
                        onChange={setquizgroup}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={() => handleSave()}
                  type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm">
                  Save
                </button>
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleRemoveQuiz}
                >
                  Remove Quiz
                </button>
                <button
                  onClick={() => setsavedquizmodel(false)}
                  type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {isOpen && (
        <div className="fixed z-100 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Password Reset
                    </h3>
                    <div className="mt-2">
                      <input type="password" value={password} onChange={handlePasswordChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="password" placeholder="New Password" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={passwordhandleSubmit}>
                  Submit
                </button>
                <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm" onClick={() => setIsOpen(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/*section 5 */}
      <div className={`bg-white h-screen flex ${viewmodule ? '' : 'hidden'}`}>

        {section === 5 && tag === 'Eng' && (
          <div className="pt-6 bg-white">
            <div className="flex mb-[10px]">
              <button className="bg-slate-700 text-white p-2 rounded-xl m-1" onClick={handlePreviousQuestion}>&lt; Left</button>
              <button className="bg-slate-700 text-white p-2 rounded-xl m-1" onClick={handleNextQuestion}>Right &gt;</button>
              <button className="absolute bg-slate-700 text-white m-1 rounded-xl p-2 top-6   right-0 p-2" onClick={() => { setviewmodule(false); setsection(4); }}>
                Back
              </button>
            </div>
            <hr />

            <div className="text-[24px] pt-[15px] pl-[5px]">
              Reading and Writing | Question ID, {questionid}
            </div>
            <div className="flex">
              <div className="w-1/2">

                <div>
                  <div dangerouslySetInnerHTML={{ __html: selectedTopic.stimulus }} className="max-h-[500px] overflow-y-auto overflow-x-hidden ml-[20px] mt-[10px]" />
                </div>

                <label className="mt-[100px] flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={hideExplanation}
                    onChange={modulehandleChange}
                    className="form-checkbox h-5 w-5 text-gray-600"
                  />
                  <span>Hide correct answer and explanation</span>
                </label>

              </div>
              <div className="w-1/2 ml-[20px] mt-[-40px] mr-[20px]">

                <div className="flex flex-row justify-between bg-gray-100">
                  <div className="flex flex-row gap-2 p-2">
                    <div className="flex flex-row items-center gap-1">
                      <div>
                        <p>Question Review</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-2 max-w-[600px] max-h-[480px] overflow-y-auto">
                  <div dangerouslySetInnerHTML={{ __html: selectedTopic.stem }} className="ml-[20px]" />

                  <>
                    <Option idx={0} data={selectedTopic.a} />
                    <Option idx={1} data={selectedTopic.b} />
                    <Option idx={2} data={selectedTopic.c} />
                    <Option idx={3} data={selectedTopic.d} />
                  </>
                </div>

                {!hideExplanation && (
                  <div className="mt-[20px]" style={{ maxHeight: '450px', overflowY: 'auto' }}>
                    <div dangerouslySetInnerHTML={{ __html: selectedTopic.rationale }} />
                  </div>
                )}

              </div>
            </div>
          </div>
        )}

        {section === 5 && tag === 'Math' && (
          <div className="bg-white">
            <div className="flex mb-[10px]">
              <button className="bg-slate-700 text-white p-2 rounded-xl m-1" onClick={handlePreviousQuestion}>&lt; Left</button>
              <button className="bg-slate-700 text-white p-2 rounded-xl m-1" onClick={handleNextQuestion}>Right &gt;</button>
              <button className="absolute bg-slate-700 text-white m-1 rounded-xl p-2 top-0 right-0 p-2" onClick={() => { setviewmodule(false); setsection(4); }}>
                Back
              </button>
            </div>
            <hr />

            <div className="text-[24px] pt-[15px] pl-[5px]">
              Math | Question ID, {questionid}
            </div>

            <div className="mt-[30px] flex" ref={boxItemLeftRef}>
              <div className="w-1/2">

                <div>
                  <div dangerouslySetInnerHTML={{ __html: selectedTopic.stem }} className="max-h-[500px] overflow-y-auto overflow-x-hidden ml-[20px] mt-[10px]" />
                </div>

                <label className="mt-[100px] flex items-center space-x-2">
                  <input
                    type="checkbox"
                    checked={hideExplanation}
                    onChange={modulehandleChange}
                    className="form-checkbox h-5 w-5 text-gray-600"
                  />
                  <span>Hide correct answer and explanation</span>
                </label>

              </div>
              <div className="w-1/2 ml-[20px] mr-[20px] mt-[-50px]">

                <div className="flex flex-row justify-between bg-gray-100">
                  <div className="flex flex-row gap-2">
                    <div className="p-2 flex flex-row items-center gap-1">
                      <div>
                        <p>Question Review</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-2 max-w-[600px] max-h-[480px] overflow-y-auto">
                  {selectedTopic.a != null ? (
                    <>
                      <Option idx={0} data={selectedTopic.a} />
                      <Option idx={1} data={selectedTopic.b} />
                      <Option idx={2} data={selectedTopic.c} />
                      <Option idx={3} data={selectedTopic.d} />
                    </>
                  ) : (
                    <input className="w-[90%] border-[1px] py-1 px-2 border-gray-300 outline-none rounded-md" type="text" />
                  )}
                </div>

                {!hideExplanation && (
                  <div className="mt-[20px]">
                    <div dangerouslySetInnerHTML={{ __html: selectedTopic.rationale2 ? selectedTopic.rationale2 : selectedTopic.rationale }} />
                  </div>
                )}

              </div>
            </div>

          </div>
        )}

      </div>

    </div>
  </>);
};

export default TDashboard;

const Option = ({ idx, data }) => {
  return (
    <div className="flex flex-row gap-1">
      <motion.div whileTap={{ scale: 0.98 }} className={`flex flex-row w-full border-[1px] rounded-md border-black gap-2 p-1 m-3 cursor-pointer relative`} >
        <div className="flex flex-row items-center">
          <p className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
            {getOptionLetter(idx)}
          </p>
        </div>
        <div dangerouslySetInnerHTML={{ __html: data }} />

      </motion.div>
    </div>
  )
}

function getOptionLetter(idx) {
  switch (idx) {
    case 0:
      return 'A'
      break;
    case 1:
      return 'B'
      break;
    case 2:
      return 'C'
      break;
    case 3:
      return 'D'
      break;
    default:
      return "A"
  }
}
