import React, { useState, useEffect } from 'react';
import axios from "axios";
import { BASELINE } from "../../util";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const GetHelp = ({ studentid }) => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`${BASELINE}get/question/set?u_id=${studentid}`)
            .then(response => {
                if (response.data && response.status === 200) {
                    setData(response.data);
                    toast.success('Data fetched successfully!');
                } else {
                    toast.error('No data found.');
                }
            })
            .catch(error => {
                console.error(error);
                toast.error('Empty Help Session');
            });
    }, [studentid]);

    const handleView = (data) => {
        console.log(data);
        var tag;
        if (data.type === 'SAT') {
            if (data.tag === 'math') {
                tag = "Math";
            } else {
                tag = "English";
            }
        }
        else if (data.type === 'ACT') {
            tag = data.tag;
        }
        else if (data.type === 'NMSQT') {
            tag = data.tag;
        }
        else if (data.type === 'SAT89') {
            tag = data.tag;
        }


        const quizData = {
            questionIds: JSON.parse(data.questionidsarr),
            tutorModeActive: false,
            timedModeActive: false,
            timeLimit: null,
            tag: tag,
            type: data.type,
        };
        localStorage.clear();
        console.log(quizData);
        if (data.type === 'SAT' || data.type === 'NMSQT' || data.type === 'SAT89') {
            navigate(`/sat/quiz/`, { state: { data: quizData } });
        }
        else if (data.type === 'ACT') {
            navigate(`/act/quiz/`, { state: { data: quizData } });
        }
    };


    const handleDrop = (testId) => {
        if (window.confirm('Are you sure you want to delete this record?')) {
            axios.delete(`${BASELINE}del/question/set`, { data: { test_id: testId } })
                .then(response => {
                    if (response.status === 200) {
                        toast.success('Record successfully deleted.');
                        // Remove the deleted item from the state to update the UI
                        setData(prevData => prevData.filter(item => item.test_id !== testId));
                    } else {
                        toast.error('Failed to delete the record.');
                    }
                })
                .catch(error => {
                    console.error('Error deleting the record:', error);
                    toast.error('Error deleting the record.');
                });
        }
    };

    const formatId = (type, date, tag) => `${type} [${new Date(date).toLocaleDateString()}], ${tag}`;

    return (
        <div className="">
            <div className="bg-white pl-6 pt-6 text-gray-500 text-sm">
                <span className="hover:underline cursor-pointer">My Home</span> &gt; <span className="text-black">Digital Test View</span> &gt; <span className="font-semibold text-black">Help Session</span>
            </div>
            <h1 className="m-4 p-1 pl-6 text-xl font-bold mt-4">Help Session</h1>
            <div className="ml-[50px] my-4">
                <table className="min-w-full border-collapse block md:table">
                    <thead className="block md:table-header-group">
                        <tr className="border-b border-gray-300 md:border-none block md:table-row absolute -top-full md:static">
                            <th className="bg-gray-100 p-4 text-center font-semibold md:border md:border-gray-500 block md:table-cell">Name</th>
                            <th className="bg-gray-100 p-4 text-center font-semibold md:border md:border-gray-500 block md:table-cell">Action</th>
                        </tr>
                    </thead>
                    <tbody className="block md:table-row-group">
                        {data.map((item) => (
                            <tr key={item.test_id} className="bg-white md:border md:border-gray-300 block md:table-row">
                                <td className="p-4 block md:table-cell">{formatId(item.type, item.date, item.tag)}</td>
                                <td className="p-4 block md:table-cell">
                                    <button onClick={() => handleView(item)} className="text-blue-500 hover:text-blue-800 font-semibold">View</button>
                                    <button onClick={() => handleDrop(item.test_id)} className="text-red-500 hover:text-red-800 font-semibold ml-4">Drop</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default GetHelp;
