import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import 'katex/dist/katex.min.css';

const DevQuestion = ({ ans, handleCrossOutOptions, handleSelectOption, currentQuestion }) => {
    const boxItemLeftRef = useRef();

    const [showCrossOutMenu, setShowCrossOutMenu] = useState(false);
    const [crossedOutOptions, setCrossedOutOptions] = useState([]);

    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {

        const script2 = document.createElement('script');
        script2.src = "https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/contrib/auto-render.min.js";
        script2.integrity = "sha384-+VBxd3r6XgURycqtZ117nYw44OOcIax56Z4dCRWbxyPt0Koah1uHoK0o4+/RRE05";
        script2.crossOrigin = "anonymous";
        script2.defer = true;

        // Handle script load
        script2.onload = () => {
            if (boxItemLeftRef.current) {
                if (window.renderMathInElement) {
                    const boxItemLeft = document.getElementById("box-item-left");
                    window.renderMathInElement(boxItemLeft, {
                        delimiters: [
                            { left: '$$', right: '$$', display: false },
                            { left: "$", right: "$", display: false },
                            { left: '\\(', right: '\\)', display: false },
                            { left: '\\[', right: '\\]', display: true },
                            { left: '\\begin{array}', right: '\\end{array}', display: true }
                        ],
                        throwOnError: false,
                    });
                }
            }
        };
        document.body.appendChild(script2);

        // Cleanup: remove the script when component unmounts
        return () => {
            document.body.removeChild(script2);
        };
    }, [currentQuestion, isEditing]);

    return (
        <>
            <div id="box-item-left" ref={boxItemLeftRef}>
                {
                    currentQuestion && (<div className="flex">
                        <div className="w-[50%]">
                            <div className="ml-[20px]" dangerouslySetInnerHTML={{ __html: currentQuestion.prompt }} />
                        </div>
                        <div className="w-[50%]">
                            <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={0} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.a} />
                            <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={1} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.b} />
                            <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={2} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.c} />
                            <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={3} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.d} />
                            <Option handleCrossOutOptions={handleCrossOutOptions} currentQuestion={currentQuestion} handleSelectOption={handleSelectOption} idx={4} showCrossOutMenu={showCrossOutMenu} crossedOutOptions={crossedOutOptions} data={currentQuestion.e} />
                        </div>
                    </div>)}
                <div className="ml-[50%]">Correct Answer: {currentQuestion.answer}</div>
                <div>
                </div>
            </div>
        </>
    )
}
export default DevQuestion;


const Option = ({ handleCrossOutOptions, currentQuestion, idx, handleSelectOption, showCrossOutMenu, crossedOutOptions, data }) => {
    return (
        <div className="flex flex-row gap-1">
            <motion.div whileTap={{ scale: 0.98 }} className={`flex flex-row w-full ${currentQuestion.selectedOption === getOptionLetter(idx).toLowerCase() ? "border-[2px]" : "border-[1px]"} rounded-md ${currentQuestion.selectedOption === getOptionLetter(idx).toLowerCase() ? "border-blue-700 bg-blue-100" : "border-black"} gap-2 p-1 m-3 cursor-pointer relative ${currentQuestion.crossedOutOptions.includes(getOptionLetter(idx).toLowerCase()) && "before:absolute before:bg-black before:w-[103%] before:h-[2px] before:top-[50%] before:-left-[6px]"}`} onClick={() => handleSelectOption(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>
                <div className="flex flex-row items-center">
                    <p className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full">
                        {getOptionLetter(idx)}
                    </p>
                </div>
                <div className="flex flex-row items-center p-[2px]" dangerouslySetInnerHTML={{ __html: data }}>
                </div>
            </motion.div>
            {showCrossOutMenu &&
                <motion.div className="flex flex-row items-center" whileTap={{ scale: 0.97 }}>
                    <div className="relative">
                        {currentQuestion.crossedOutOptions.includes(getOptionLetter(idx).toLowerCase()) ?
                            (<p className="cursor-pointer underline" onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>Undo</p>) :

                            <>
                                <p onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())} className="flex flex-row items-center justify-center p-1 w-6 h-6 border-[1px] border-black rounded-full cursor-pointer">
                                    {getOptionLetter(idx)}
                                </p>
                                <div className="absolute w-6 h-[1.5px] bg-black top-3 cursor-pointer" onClick={() => handleCrossOutOptions(currentQuestion.questionIds, getOptionLetter(idx).toLowerCase())}>
                                </div>
                            </>

                        }


                    </div>

                </motion.div>
            }
        </div>
    )
}

function getOptionLetter(idx) {
    switch (idx) {
        case 0:
            return 'A'
            break;
        case 1:
            return 'B'
            break;
        case 2:
            return 'C'
            break;
        case 3:
            return 'D'
            break;
        case 4:
            return 'E'
            break;
        default:
            return "A"
    }
}