import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASELINE } from "../../util";

const NavBar = ({
    index,
    questions,
    code,
    topics,
    currentQuestion,
    testIdOptions,
    handleUpdate,
    qidOptions,
    handleTestIdChange,
    handleNext,
    handleBack,
    handleOptionClick,
    handleNext2,
    handleBack2
}) => {
    const navigate = useNavigate();
    const [selectedTestId, setselectedTestId] = useState("");
    const [subject, setSubject] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [selectedHeader, setSelectedHeader] = useState(null);

    const fetchTestName = async (q_id) => {
        try {
            let res = await axios.get(BASELINE + "get/apcalc/testname", {
                params: {
                    q_id: q_id,
                },
            });
            setSubject(res.data); // Set subject data to state
        } catch (err) {
            console.error(err);
        }
    };

    // Run fetchTestName on initial load with q_id "D03"
    useEffect(() => {
        fetchTestName({ exam: "AP 1969_BC MC" }); // Initial fetch with q_id "D03"
    }, []);

    // Run fetchTestName every time selectedTestId changes
    useEffect(() => {
        if (selectedTestId) {
            fetchTestName(selectedTestId);
        }
    }, [selectedTestId]);

    const handleHeaderClick = (header) => {
        setSelectedHeader(selectedHeader === header ? null : header);
    };

    const handleTopicClick = (topicItem) => {
        setMenuOpen(false);
        navigate(`/devapcalc/${topicItem.code}`);
    };

    return (
        <nav className="bg-slate-700 text-white p-4 flex justify-between items-center relative">
            {/* Hamburger Menu */}
            <div className="flex items-center">
                <div
                    className="hamburger-menu cursor-pointer mr-4"
                    onClick={() => setMenuOpen(!menuOpen)}
                >
                    <div className="w-6 h-0.5 bg-white mb-1"></div>
                    <div className="w-6 h-0.5 bg-white mb-1"></div>
                    <div className="w-6 h-0.5 bg-white"></div>
                </div>

                {/* Menu Items */}
                {menuOpen && (
                    <div className="absolute top-16 left-0 bg-white text-black w-80 p-4 shadow-md z-50 overflow-y-auto max-h-[80vh]">
                        {Object.keys(topics).map((header, index) => (
                            <div key={index}>
                                <div
                                    className="py-2 px-4 hover:bg-gray-200 cursor-pointer font-bold"
                                    onClick={() => handleHeaderClick(header)}
                                >
                                    {header}
                                </div>
                                {selectedHeader === header && (
                                    <div className="ml-4">
                                        {Object.keys(topics[header]).map((subtopicKey, subIndex) => (
                                            <div key={subIndex}>
                                                <div className="py-1 px-4 font-semibold">{subtopicKey}</div>
                                                {topics[header][subtopicKey].topics.map((topicItem, topicIndex) => (
                                                    <div
                                                        key={topicIndex}
                                                        className="py-1 px-8 hover:bg-gray-100 cursor-pointer"
                                                        onClick={() => handleTopicClick(topicItem)}
                                                    >
                                                        {topicItem.title}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Rest of your NavBar code */}
            <div className="flex gap-4">
                {code === false && <>
                    <div>
                        <label htmlFor="testId" className="mr-2">
                            exam ID:
                        </label>
                        <select
                            id="testId"
                            className="bg-slate-600 text-white p-1 rounded"
                            onChange={(e) => {
                                const selectedTest = testIdOptions[e.target.selectedIndex]; // Get selected test object
                                setselectedTestId(selectedTest); // Update the state with selected test
                                handleTestIdChange(selectedTest.exam); // Call handler with exam_code
                                navigate(`/devapcalc/${selectedTest.id}`); // Navigate with the correct qid immediately
                            }}
                        >
                            {testIdOptions.map((testId, index) => (
                                <option key={index} value={testId.exam}>
                                    {testId.exam}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label htmlFor="subject" className="mr-2">
                            Section:
                        </label>
                        <select
                            id="subject"
                            className="bg-slate-600 text-white p-1 rounded"
                            onChange={(e) => {
                                const data = subject[e.target.selectedIndex];
                                navigate(`/devapcalc/${data.id}`);
                            }}
                        >
                            {subject.map((subject, index) => (
                                <option key={index} value={subject.section}>
                                    {subject.section}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex">
                        <div className="mr-2 mt-[8px]">Mode:</div>
                        <div id="qid" className="bg-slate-600 text-white p-1 rounded">
                            {currentQuestion?.mode}
                        </div>
                    </div>

                    <div className="flex">
                        <div className="mr-2 mt-[8px]">Question #:</div>
                        <div id="qid" className="bg-slate-600 text-white p-1 rounded">
                            <input
                                type="number"
                                value={qidOptions}
                                onChange={(e) => {
                                    navigate(`/devapcalc/${e.target.value}`);
                                }}
                                className="bg-slate-600 text-white p-1 rounded"
                            />
                        </div>
                    </div>
                </>}

                {code === true && <>
                    <div className="flex gap-2">
                        <div id="qid" className="bg-slate-600 text-white p-1 rounded">
                            {index}
                        </div>
                        <div className="mr-2 mt-[8px]">Out Of</div>
                        <div id="qid" className="bg-slate-600 text-white p-1 rounded">
                            {questions?.length}
                        </div>
                    </div>
                </>}
                <button className="ml-[100px]" onClick={() => window.location.reload()}>
                    Reset
                </button>
                <button onClick={() => handleUpdate()}>Update</button>
                {code === false && <>
                    <button onClick={() => handleBack()}>Back</button>
                    <button onClick={() => handleNext()}>Next</button>
                </>}
                {code === true && <>
                    <button onClick={() => handleBack2()}>Back</button>
                    <button onClick={() => handleNext2()}>Next</button>
                </>}
            </div>
        </nav>
    );
};

export default NavBar;
